import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import NotaryGlba from 'model/notaryGlba';
import { nullsToEmptyStrings, showBriefSuccess, showError } from "helpers/utilHelper";
import { useNavigate } from "react-router-dom";
import { route } from "helpers/routeHelper";
import { useDispatch, useSelector } from "react-redux";
import { Row, Form, Button, Label, Input, FormFeedback } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Col from "components/Shared/Col";
import { ValidationException } from "helpers/errorHelper";
import { updateGlbaSecurity, doUpdateGlbaSecurityCleanup } from "store/actions";
import classnames from "classnames";

const FormSecurityPractices = props => {

  const { defaultValues, navRoutes } = props;

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // router hook that helps redirect
  const navigate = useNavigate();

  /********** STATE **********/
  const [nextRoute, setNextRoute] = useState('');
  const [submitCount, setSubmitCount] = useState(0);

  const isNoChecked = (value) => value === false || value === 0;

  // get redux state from the store
  const { isSaveSecurityInProgress, savedSecurity, savedSecurityError } = useSelector(state => state.Profile.GlbaForm);

  /********** FORM CONFIG **********/

  const formInitialValues = {
    isCustomerInfoProtected: '',
    isCustomerInfoStored: '',
    isSubcontractorHired: '',
    areSystemsUpdated: '',
    customerInfoStorageType: '',
    customerInfoStorageDetails: '',
    hiredSubcontractor: '',
    ...nullsToEmptyStrings(defaultValues),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: !!submitCount,
    validateOnBlur: true,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      areSystemsUpdated: Yup.mixed().required("Please choose one value"),
      isCustomerInfoProtected: Yup.mixed().required("Please choose one value"),
      isCustomerInfoStored: Yup.mixed().required("Please choose one value"),
      isSubcontractorHired: Yup.mixed().required("Please choose one value"),
      hiredSubcontractor: Yup.string()
        .when('isSubcontractorHired', {
          is: (isSubcontractorHired) =>
            Boolean(isSubcontractorHired),
          then: Yup.string().required("Field is required"),
          otherwise: Yup.string().nullable(),
        }),
      customerInfoStorageType: Yup.array()
        .min(1, 'You must select at least one option.')
        .required('This field is required.'),
      customerInfoStorageDetails: Yup.string().when('customerInfoStorageType', {
        is: (types) => Array.isArray(types) && types.includes(NotaryGlba.CUSTOMER_INFO_STORAGE_TYPE_OTHER),
        then: Yup.string()
          .required('Please provide details for "Other".')
          .min(3, 'Details must be at least 3 characters long.'),
        otherwise: Yup.string().notRequired(),
      }),
    }),
    onSubmit: values => dispatch(updateGlbaSecurity(defaultValues.id, values))
  });


  /********** EFFECTS **********/
  useEffect(() => {
    if (savedSecurity === true) {
      showBriefSuccess('Security Practices has been saved');

      dispatch(doUpdateGlbaSecurityCleanup());

      navigate(route(nextRoute));

    } else if (savedSecurity === false) {

      showError('Unable to save security practices information');
      // see if the save failed due to validation
      if (savedSecurityError instanceof ValidationException) {
        // show an error on each invalid field
        for (const [name, message] of Object.entries(savedSecurityError.fields)) {
          formik.setFieldError(name, message);
        }
      }
      // enable the save button
      formik.setSubmitting(false);
    }
  }, [savedSecurity]);

  /********** EVENT HANDLERS **********/
  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  /********** OTHER **********/
  const setCustomerInfoStorageType = (value) => {
    const option = parseInt(value);
    let storageTypeList = formik.values.customerInfoStorageType ? [...formik.values.customerInfoStorageType] : [];
    if (!storageTypeList.includes(option)) {
      storageTypeList.push(option);
    } else {
      storageTypeList.splice(storageTypeList.indexOf(option), 1)
    }
    formik.setFieldValue('customerInfoStorageType', storageTypeList)
  }

  return <React.Fragment>
    <Form className="pt-4">
      <div className="card-section">
        <Row>
          <Col lg="6">
            <div className="mb-4">
              <h6 className='mb-2'>Does Notary maintain appropriate safeguards to protect customer information?</h6>
              <div className={classnames({ 'is-invalid': !!formik.errors.isCustomerInfoProtected }, 'form-check my-2')}>
                <Input
                  className="form-check-input"
                  type="radio"
                  id="self"
                  name="isCustomerInfoProtected"
                  value={true}
                  checked={formik.values.isCustomerInfoProtected}
                  onChange={() => formik.setFieldValue('isCustomerInfoProtected', true)}
                />
                <Label className="form-check-label" htmlFor="yes">Yes</Label>
              </div>

              <div className={classnames({ 'is-invalid': !!formik.errors.isCustomerInfoProtected }, 'form-check my-2')}>
                <Input
                  className="form-check-input"
                  type="radio"
                  id="no"
                  name="isCustomerInfoProtected"
                  value={false}
                  checked={isNoChecked(formik.values.isCustomerInfoProtected)}
                  onChange={() => formik.setFieldValue('isCustomerInfoProtected', false)}
                />
                <Label className="form-check-label" htmlFor="no">No</Label>
              </div>
              {!!formik.errors.isCustomerInfoProtected && <FormFeedback>{formik.errors.isCustomerInfoProtected}</FormFeedback>}
            </div>

            <div className="mb-4">
              <h6 className='mb-2'>Does Notary host a website or application that stores customer information?</h6>
              <div className={classnames({ 'is-invalid': !!formik.errors.isCustomerInfoStored }, 'form-check my-2')}>
                <input
                  className="form-check-input"
                  type="radio"
                  id="yes"
                  name="isCustomerInfoStored"
                  value={true}
                  checked={formik.values.isCustomerInfoStored}
                  onChange={() => formik.setFieldValue('isCustomerInfoStored', true)}
                />
                <Label className="form-check-label" htmlFor="yes">Yes</Label>
              </div>

              <div className={classnames({ 'is-invalid': !!formik.errors.isCustomerInfoStored }, 'form-check my-2')}>
                <input
                  className="form-check-input"
                  type="radio"
                  id="no"
                  name="isCustomerInfoStored"
                  value={false}
                  checked={isNoChecked(formik.values.isCustomerInfoStored)}
                  onChange={() => formik.setFieldValue('isCustomerInfoStored', false)}
                />
                <Label className="form-check-label" htmlFor="no">No</Label>
              </div>
              {!!formik.errors.isCustomerInfoStored && <FormFeedback>{formik.errors.isCustomerInfoStored}</FormFeedback>}
            </div>

            <div className="mb-4">
              <h6 className='mb-2'>Does Notary hire a subcontractor when handling customer information (excluding computer services) from-and-to Mavsign or Dealership?</h6>
              <div className={classnames({ 'is-invalid': !!formik.errors.isSubcontractorHired }, 'form-check my-2')}>
                <input
                  className="form-check-input"
                  type="radio"
                  id="yes"
                  name="isSubcontractorHired"
                  value={true}
                  checked={formik.values.isSubcontractorHired}
                  onChange={() => formik.setFieldValue('isSubcontractorHired', true)}
                />
                <Label className="form-check-label" htmlFor="yes">Yes</Label>
              </div>

              {/* Conditional Input for Hired Subcontractor */}
              {!!formik.values.isSubcontractorHired && (<>
                <Input
                  className={classnames('form-control mt-3', { 'is-invalid': !!formik.errors.authorizedRepresentative })}
                  placeholder="Explain"
                  type="text"
                  name="hiredSubcontractor"
                  onChange={formik.handleChange}
                  onFocus={onFieldFocused}
                  value={formik.values.hiredSubcontractor}
                  invalid={!!formik.errors.hiredSubcontractor}
                />
                {formik.errors.hiredSubcontractor && (<FormFeedback type="invalid">{formik.errors.hiredSubcontractor}</FormFeedback>)}
              </>
              )}

              <div className={classnames({ 'is-invalid': !!formik.errors.isSubcontractorHired }, 'form-check my-2')}>
                <input
                  className="form-check-input"
                  type="radio"
                  id="no"
                  name="isSubcontractorHired"
                  value={false}
                  checked={isNoChecked(formik.values.isSubcontractorHired)}
                  onChange={() => formik.setFieldValue('isSubcontractorHired', false)}
                />
                <Label className="form-check-label" htmlFor="no">No</Label>
              </div>
              {!!formik.errors.isSubcontractorHired && <FormFeedback>{formik.errors.isSubcontractorHired}</FormFeedback>}
            </div>
          </Col>

          <Col lg="6">
            <div className="mb-4">
              <h6 className='mb-2'>Are Notary’s operating systems, firewalls, and anti-virus software up-to-date?</h6>
              <div className={classnames({ 'is-invalid': !!formik.errors.areSystemsUpdated }, 'form-check my-2')}>
                <input
                  className="form-check-input"
                  type="radio"
                  id="yes"
                  name="areSystemsUpdated"
                  value={true}
                  checked={formik.values.areSystemsUpdated}
                  onChange={() => formik.setFieldValue('areSystemsUpdated', true)}
                />
                <Label className="form-check-label" htmlFor="self">Yes</Label>
              </div>

              <div className={classnames({ 'is-invalid': !!formik.errors.areSystemsUpdated }, 'form-check my-2')}>
                <input
                  className="form-check-input"
                  type="radio"
                  id="no"
                  name="areSystemsUpdated"
                  value={false}
                  checked={isNoChecked(formik.values.areSystemsUpdated)}
                  onChange={() => formik.setFieldValue('areSystemsUpdated', false)}
                />
                <Label className="form-check-label" htmlFor="no">No</Label>
              </div>
              {!!formik.errors.areSystemsUpdated && <FormFeedback>{formik.errors.areSystemsUpdated}</FormFeedback>}
            </div>

            <div className="mb-4">
              <h6 className='mb-2'>How does Notary store customer information? (select all that apply)</h6>
              <div className={classnames({ 'is-invalid': !!formik.errors.customerInfoStorageType }, 'form-check my-2')}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="customerInfoStorageType"
                  id="hardDrive"
                  value={NotaryGlba.CUSTOMER_INFO_STORAGE_TYPE_HARD_DRIVE}
                  checked={formik.values?.customerInfoStorageType?.includes(NotaryGlba.CUSTOMER_INFO_STORAGE_TYPE_HARD_DRIVE)}
                  onChange={(e) => setCustomerInfoStorageType(e.target.value)}
                />
                <Label className="form-check-label" htmlFor="hardDrive">Computer and phone hard drive</Label>
              </div>

              <div className={classnames({ 'is-invalid': !!formik.errors.customerInfoStorageType }, 'form-check my-2')}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="cabinet"
                  name="customerInfoStorageType"
                  value={NotaryGlba.CUSTOMER_INFO_STORAGE_TYPE_CABINET}
                  checked={formik.values?.customerInfoStorageType?.includes(NotaryGlba.CUSTOMER_INFO_STORAGE_TYPE_CABINET)}
                  onChange={(e) => setCustomerInfoStorageType(e.target.value)}
                />
                <Label className="form-check-label" htmlFor="cabinet">Physical locked storage cabinet</Label>
              </div>

              <div className={classnames({ 'is-invalid': !!formik.errors.customerInfoStorageType }, 'form-check my-2')}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="cloud"
                  name="customerInfoStorageType"
                  value={NotaryGlba.CUSTOMER_INFO_STORAGE_TYPE_CLOUD}
                  checked={formik.values?.customerInfoStorageType?.includes(NotaryGlba.CUSTOMER_INFO_STORAGE_TYPE_CLOUD)}
                  onChange={(e) => setCustomerInfoStorageType(e.target.value)}
                />
                <Label className="form-check-label" htmlFor="cloud">Cloud service or 3rd party application</Label>
              </div>

              <div className={classnames({ 'is-invalid': !!formik.errors.customerInfoStorageType }, 'form-check my-2')}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="other"
                  name="customerInfoStorageType"
                  value={NotaryGlba.CUSTOMER_INFO_STORAGE_TYPE_OTHER}
                  checked={formik.values?.customerInfoStorageType?.includes(NotaryGlba.CUSTOMER_INFO_STORAGE_TYPE_OTHER)}
                  onChange={(e) => setCustomerInfoStorageType(e.target.value)}
                />
                <Label className="form-check-label" htmlFor="other">Other</Label>
              </div>
              {!!formik.errors.customerInfoStorageType && <FormFeedback>{formik.errors.customerInfoStorageType}</FormFeedback>}

              {formik.values.customerInfoStorageType.includes(NotaryGlba.CUSTOMER_INFO_STORAGE_TYPE_OTHER) && (<>
                <Input
                  placeholder="Explain"
                  type="text"
                  className="form-control mt-3"
                  name="customerInfoStorageDetails"
                  onChange={formik.handleChange}
                  onFocus={onFieldFocused}
                  value={formik.values.customerInfoStorageDetails}
                  invalid={!!formik.errors.customerInfoStorageDetails}
                />
                {formik.errors.customerInfoStorageDetails && <FormFeedback>{formik.errors.customerInfoStorageDetails}</FormFeedback>}
              </>)}
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="text-end pt-4">
            <Button
              type="button"
              color="primary"
              className="ms-2 btn-faded"
              onClick={() => { setNextRoute(navRoutes?.prev); formik.handleSubmit() }}
              disabled={formik.isSubmitting}
            >
              {isSaveSecurityInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
              Previous
            </Button>
            <Button
              type="button"
              color="primary"
              className="ms-2"
              onClick={() => { setNextRoute(navRoutes?.next); formik.handleSubmit(); setSubmitCount(prev => ++prev) }}
              disabled={formik.isSubmitting}
            >
              {isSaveSecurityInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
              Next
            </Button>
          </Col>
        </Row>
      </div>
    </Form >
  </React.Fragment >
}

FormSecurityPractices.propTypes = {
  defaultValues: PropTypes.object,
  navRoutes: PropTypes.object,
};

export default FormSecurityPractices;