import { takeEvery, put, call } from "redux-saga/effects";

import {
  GET_PROFILE,
  AGREE_TO_PROFILE_TERMS,
  SAVE_PROFILE_BILLING,
  SAVE_PROFILE_SHIPPING,
  SAVE_PROFILE_CONTACT,
  SAVE_PROFILE_CREDENTIALS,
  SAVE_PROFILE_EXPERIENCE,
  SAVE_PROFILE_AREA,
  SAVE_PROFILE_SHIPPING_AREA,
  CREATE_GLBA,
  UPDATE_GLBA_GENERAL,
  UPDATE_GLBA_SECURITY,
  UPDATE_GLBA_ACCESS,
  SAVE_OUT_OF_OFFICE
} from "./actionTypes";

import {
  getProfileOk,
  getProfileErr,
  saveProfileOk,
  saveProfileErr,
  createGlbaOk,
  createGlbaErr,
  updateGlbaGeneralOk,
  updateGlbaGeneralErr,
  updateGlbaSecurityOk,
  updateGlbaSecurityErr,
  updateGlbaAccessOk,
  updateGlbaAccessErr,
  saveOutOfOfficeOk,
  saveOutOfOfficeErr,
} from "./actions";

import {
  getMyProfile,
  agreeToProfileTerms,
  saveMyProfileBilling,
  saveMyProfileShipping,
  saveMyProfileContact,
  saveMyProfileCredentials,
  saveMyProfileExperience,
  saveMyProfileArea,
  saveMyProfileShippingArea,
  createNotaryGlba,
  updateGeneral,
  updateSecurity,
  updateAccess,
  saveOutOfOffice
} from "helpers/backendHelper";

/********** GET PROFILE **********/

function* onGetProfile() {
  try {
    const response = yield call(getMyProfile);
    yield put(getProfileOk(response));
  } catch (error) {
    yield put(getProfileErr(error));
  }
}

/********** SAVE PROFILE **********/

function* onAgreeToProfileTerms({ payload: { data } }) {
  try {
    const response = yield call(agreeToProfileTerms, data);
    yield put(saveProfileOk(response));
  } catch (error) {
    yield put(saveProfileErr(error));
  }
}

function* onSaveProfileBilling({ payload: { data } }) {
  try {
    const response = yield call(saveMyProfileBilling, data);
    yield put(saveProfileOk(response));
  } catch (error) {
    yield put(saveProfileErr(error));
  }
}

function* onSaveProfileShipping({ payload: { data } }) {
  try {
    const response = yield call(saveMyProfileShipping, data);
    yield put(saveProfileOk(response));
  } catch (error) {
    yield put(saveProfileErr(error));
  }
}

function* onSaveProfileContact({ payload: { data } }) {
  try {
    const response = yield call(saveMyProfileContact, data);
    yield put(saveProfileOk(response));
  } catch (error) {
    yield put(saveProfileErr(error));
  }
}

function* onSaveProfileCredentials({ payload: { data } }) {
  try {
    const response = yield call(saveMyProfileCredentials, data);
    yield put(saveProfileOk(response));
  } catch (error) {
    yield put(saveProfileErr(error));
  }
}

function* onSaveProfileExperience({ payload: { data } }) {
  try {
    const response = yield call(saveMyProfileExperience, data);
    yield put(saveProfileOk(response));
  } catch (error) {
    yield put(saveProfileErr(error));
  }
}

function* onSaveProfileArea({ payload: { data } }) {
  try {
    const response = yield call(saveMyProfileArea, data);
    yield put(saveProfileOk(response));
  } catch (error) {
    yield put(saveProfileErr(error));
  }
}

function* onSaveProfileShippingArea({ payload: { data } }) {
  try {
    const response = yield call(saveMyProfileShippingArea, data);
    yield put(saveProfileOk(response));
  } catch (error) {
    yield put(saveProfileErr(error));
  }
}

function* onCreateGlba({ payload: { id, data } }) {
  try {
    const response = yield call(createNotaryGlba, id, data);
    yield put(createGlbaOk(response));
  } catch (error) {
    yield put(createGlbaErr(error));
  }
}

function* onUpdateGlbaGeneral({ payload: { id, data } }) {
  try {
    const response = yield call(updateGeneral, id, data);
    yield put(updateGlbaGeneralOk(response));
  } catch (error) {
    yield put(updateGlbaGeneralErr(error));
  }
}

function* onUpdateGlbaSecurity({ payload: { id, data } }) {
  try {
    const response = yield call(updateSecurity, id, data);
    yield put(updateGlbaSecurityOk(response));
  } catch (error) {
    yield put(updateGlbaSecurityErr(error));
  }
}

function* onUpdateGlbaAccess({ payload: { id, data } }) {
  try {
    const response = yield call(updateAccess, id, data);
    yield put(updateGlbaAccessOk(response));
  } catch (error) {
    yield put(updateGlbaAccessErr(error));
  }
}

function* onSaveProfileOutOfOffice({ payload: { data } }) {
  try {
    const response = yield call(saveOutOfOffice, data);
    yield put(saveOutOfOfficeOk(response));
  } catch (error) {
    yield put(saveOutOfOfficeErr(error));
  }
}

function* profileSaga() {
  yield takeEvery(GET_PROFILE, onGetProfile);
  yield takeEvery(AGREE_TO_PROFILE_TERMS, onAgreeToProfileTerms);
  yield takeEvery(SAVE_PROFILE_BILLING, onSaveProfileBilling);
  yield takeEvery(SAVE_PROFILE_SHIPPING, onSaveProfileShipping);
  yield takeEvery(SAVE_PROFILE_CONTACT, onSaveProfileContact);
  yield takeEvery(SAVE_PROFILE_CREDENTIALS, onSaveProfileCredentials);
  yield takeEvery(SAVE_PROFILE_EXPERIENCE, onSaveProfileExperience);
  yield takeEvery(SAVE_PROFILE_AREA, onSaveProfileArea);
  yield takeEvery(SAVE_PROFILE_SHIPPING_AREA, onSaveProfileShippingArea);
  yield takeEvery(CREATE_GLBA, onCreateGlba);
  yield takeEvery(UPDATE_GLBA_GENERAL, onUpdateGlbaGeneral);
  yield takeEvery(UPDATE_GLBA_SECURITY, onUpdateGlbaSecurity);
  yield takeEvery(UPDATE_GLBA_ACCESS, onUpdateGlbaAccess);
  yield takeEvery(SAVE_OUT_OF_OFFICE, onSaveProfileOutOfOffice);
}

export default profileSaga;
