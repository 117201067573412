import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { NavItem, NavLink } from "reactstrap";
import { route, routes } from 'helpers/routeHelper';
import classnames from "classnames";

const FormSteps = props => {

  const { currentStep, setNavRoutes } = props;

  // router hook that helps redirect
  const navigate = useNavigate();

  useEffect(() => {
    // set the 'prev' and 'next' rountes of the parent component
    setNavRoutes(routes => ({
      next: steps[currentIndex() + 1]?.route || routes.next,
      prev: steps[currentIndex() - 1]?.route || routes.prev,
    }));
  }, [currentStep]);

  /********** WIZARD CONFIG **********/

  // the list of wizard steps to display
  const steps = [];
  steps.push({
    key: 'general',
    name: 'General Information (1-5)',
    route: routes.glba_general_information,
  });
  steps.push({
    key: 'security',
    name: 'Security Practices (6-10)',
    route: routes.glba_security_practices,
  });
  steps.push({
    key: 'policies',
    name: 'Access and Retention Policies (11-15)',
    route: routes.glba_access_retention_policies,
  });

  const goToStep = index => navigate(route(steps[index].route));

  const currentIndex = () => steps.findIndex(s => s.key == currentStep);

  return <React.Fragment>
    <div className="wizard clearfix">
      <div className="steps clearfix">
        <ul>
          {steps.map((step, index) => {
            return <NavItem className={`${classnames({ current: currentStep === step.key })} col-xl-2`} key={index}>
              <NavLink className={classnames({ active: currentStep === step.key })} onClick={() => goToStep(index)} disabled={currentIndex() <= index}>
                <span className="number">{index + 1}</span> {step.name}
              </NavLink>
            </NavItem>
          })}
        </ul>
      </div>
    </div>
  </React.Fragment>
}

FormSteps.propTypes = {
  currentStep: PropTypes.string.isRequired,
  setNavRoutes: PropTypes.func,
}

export default FormSteps;