import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, Input, Label, Row, Col, Form, FormFeedback } from 'reactstrap';
import * as Yup from "yup";
import { useFormik } from "formik";
import TCSection from "./Partial/Section/TC";
import { useDispatch, useSelector } from 'react-redux';
import { agreeToProfileTerms } from 'store/actions';
import { useProfile } from 'context/profile';
import { showError, showSuccess } from 'helpers/utilHelper';

const TermsOfServiceModal = () => {

  const { notary, isProfileSetupRequired, refreshProfile } = useProfile();

  const dispatch = useDispatch();

  // isSaveInProgress - TRUE if a save request is in progress, FALSE otherwise
  // saved - TRUE if a save was attempted and was successfull, FALSE if it failed, NULL if no save was attempted yet
  const { isSaveInProgress, saved } = useSelector(state => state.Profile.Form);

  /********** STATE **********/

  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

  /********** FORM CONFIG **********/

  const formInitialValues = {
    fullName: '',
    company: '',
    title: '',
    agreeTerms: false
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      fullName: Yup.string().trim().required('Field is required'),
      company: Yup.string().trim(),
      title: Yup.string().trim(),
      agreeTerms: Yup.bool().required('Field is required'),
    }),
    onSubmit: values => dispatch(agreeToProfileTerms(values)),
  });

  /********** EFFECTS **********/

  useEffect(() => {
    if (!isProfileSetupRequired() && !!notary.forceTermsAgreement) {
      setIsTermsModalOpen(true);
    }
  }, [notary])

  // runs after a save attempt
  useEffect(() => {
    if (!isProfileSetupRequired() && !!notary.forceTermsAgreement) {
      if (saved === true) {
        showSuccess('Agreement saved');
        // since we have agreed to the terms of the profile
        // we have to reload the profile context
        refreshProfile();
        // Closing the modal
        setIsTermsModalOpen(false);
      } else if (saved === false) {
        showError('Unable to save agreement');
      }
    }
  }, [saved]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  return <Modal
    isOpen={isTermsModalOpen}
    centered
    className="terms-modal modal-size my-10"
    size="xl"
  >
    <div className="terms-modal-header my-3">
      <h4 className="terms-modal-title mb-0">New Terms of Service Agreement for Notaries</h4>
    </div>
    <ModalBody>
      <div className='terms-modal-content'>
        <div className='terms-scroll-content'>
          <TCSection />
        </div>
      </div>
      <Form>
        <div className='mt-4'>
          <Row>
            <Col>
              <Row className="mb-4">
                <Label className="col-sm-2 col-form-label">Full Name *</Label>
                <Col sm={10}>
                  <Input type="text" className="form-control" placeholder="Your name" name="fullName" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.fullName} invalid={!!formik.errors.fullName} />
                  {!!formik.errors.fullName && <FormFeedback type="invalid">{formik.errors.fullName}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-2 col-form-label">Company</Label>
                <Col sm={10}>
                  <Input type="text" className="form-control" placeholder="Company name" name="company" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.company} invalid={!!formik.errors.company} />
                  {!!formik.errors.company && <FormFeedback type="invalid">{formik.errors.company}</FormFeedback>}
                </Col>
              </Row>
              <Row className="mb-4">
                <Label className="col-sm-2 col-form-label">Title</Label>
                <Col sm={10}>
                  <Input type="text" className="form-control" placeholder="Your title" name="title" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.title} invalid={!!formik.errors.title} />
                  {!!formik.errors.title && <FormFeedback type="invalid">{formik.errors.title}</FormFeedback>}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-3">
          <div className="d-flex">
            <Input
              type="checkbox"
              id="agreeTerms"
              name="agreeTerms"
              className="form-check-input-lg me-2"
              onChange={(e) => formik.setFieldValue("agreeTerms", e.target.checked)}
            />
            <Label htmlFor="agreeTerms">I have read and agree to the Terms & Conditions.</Label>
          </div>
          <Button type="button" color="primary" onClick={formik.handleSubmit} disabled={!formik.values.agreeTerms || !!isSaveInProgress}>
            {!!isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
            Agree & Continue
          </Button>
        </div>
      </Form>
    </ModalBody>
  </Modal>
};

export default TermsOfServiceModal;