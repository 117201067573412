import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { Link, useParams, useLocation } from "react-router-dom";
import contactIcon from "assets/images/contact-icon.svg";
import customerIcon from "assets/images/contact-customer.svg";
import schedulerIcon from "assets/images/contact-scheduler.svg";
import phoneIcon from "assets/images/contact-phone-icon.svg";
import chatIcon from "assets/images/contact-chat-icon.svg";
import videoIcon from "assets/images/contact-video-icon.svg";
import { createOrderMeeting } from "helpers/backendHelper";
import Message from "model/message";
import { route, routes } from "helpers/routeHelper";
import { openInNewWindow, showError } from "helpers/utilHelper";
import { useSelector } from "react-redux";
import { matchPath } from "react-router";

const ContactDesktop = () => {
  // get order id from url
  let { id } = useParams();
  id = parseInt(id);

  const location = useLocation();

  const showRoute = !!matchPath({ path: routes.view_notary_bid }, location.pathname);

  const order = useSelector(state => state.Order.Single.order);

  const { notaryBid } = useSelector(state => state.NotaryBid.Single);

  const [contactModal, setContactModal] = useState(false);

  const [detailedContactModal, setDetailedContactModal] = useState(false);

  const [selectedPerson, setSelectedPerson] = useState();

  const toggle = () => setContactModal(!contactModal);

  const toggleDetailed = () => {
    setDetailedContactModal(current => !current);
  };


  const contactCustomer = event => {
    // prevent Link component from redirecting
    event.preventDefault();

    setSelectedPerson({ orderSignerFullName: order?.orderSignerFullName, phone: order?.orderSignerPhone });

    toggleDetailed();
  };

  const contactScheduler = event => {
    // prevent Link component from redirecting
    event.preventDefault();

    setSelectedPerson({
      schedulerFullName: !showRoute ? order?.schedulerFullName : notaryBid?.schedulerFullName,
      phone: !showRoute ? order?.schedulerPhone : notaryBid?.schedulerPhone
    });

    toggleDetailed();
  };

  const startVideoCall = event => {
    event.preventDefault();

    if (selectedPerson.schedulerFullName) {
      createMeeting("scheduler");
    }

    if (selectedPerson.orderSignerFullName) {
      createMeeting("customer");
    }
  };

  const createMeeting = async role => {
    try {
      const { link } = await createOrderMeeting(id, role);
      if (!link) {
        throw new Error();
      } else {
        openInNewWindow(link);
      }
    } catch (err) {
      showError("Unable to start meeting");
    }
  };

  return (
    <React.Fragment>
      <div className="contact-btn btn header-item" onClick={toggle}>
        <img className="contact-btn-icon" alt="contact" src={contactIcon} />
      </div>
      <Modal
        className="contact-modal"
        isOpen={contactModal}
        toggle={toggle}
        centered
        fade={false}
        backdrop={!detailedContactModal}
      >
        <button type="button" className="btn-close ms-auto p-2" aria-label="Close" onClick={toggle} />
        <div className="contact-modal-header">
          <h4 className="contact-modal-title">Select recipient</h4>
          <p className="contact-modal-subtitle">Choose the recipient from below</p>
        </div>
        <ModalBody>
          <ul className="list-unstyled chat-list" id="recent-list">
            {!showRoute &&
              <li>
                <Link to="" onClick={contactCustomer}>
                  <div className="d-flex">
                    <div className="align-self-center me-3">
                      <img src={customerIcon} alt="chat" />
                    </div>

                    <div className="flex-grow-1 overflow-hidden my-auto">
                      <h5 className="contact-item-title text-truncate">
                        {order?.orderSignerFullName}
                      </h5>
                      <p className="contact-item-subtitle text-truncate">
                        Customer
                      </p>
                    </div>
                  </div>
                </Link>
              </li>
            }
            <li>
              <Link to="" onClick={contactScheduler}>
                <div className="d-flex">
                  <div className="align-self-center me-3">
                    <img src={schedulerIcon} alt="chat" />
                  </div>

                  <div className="flex-grow-1 overflow-hidden my-auto">
                    <h5 className="contact-item-title text-truncate">
                      {!showRoute ? order?.schedulerFullName : notaryBid?.schedulerFullName}
                    </h5>
                    <p className="contact-item-subtitle text-truncate">
                      Scheduler
                    </p>
                  </div>
                </div>
              </Link>
            </li>
          </ul>
        </ModalBody>
      </Modal>
      <Modal
        className="contact-modal"
        isOpen={detailedContactModal}
        toggle={toggleDetailed}
        centered
        fade={false}
        onOpened={() => setContactModal(false)}
      >
        <button type="button" className="btn-close ms-auto p-2" aria-label="Close" onClick={toggleDetailed} />
        <div className="contact-modal-header">
          <h4 className="contact-modal-title">Contact options</h4>
          <p className="contact-modal-subtitle">Choose one of the contact options</p>
        </div>
        <ModalBody>
          <ul className="list-unstyled chat-list" id="recent-list">
            <li>
              <Link to="" className="pe-none">
                <div className="d-flex">
                  <div className="align-self-center me-3">
                    <img src={phoneIcon} alt="chat" />
                  </div>

                  <div className="flex-grow-1 overflow-hidden my-auto">
                    <h5 className="contact-item-title text-truncate">
                      Phone call
                    </h5>
                    <p className="contact-item-subtitle text-truncate">
                      {selectedPerson?.phone || "--"}
                    </p>
                  </div>
                </div>
              </Link>
            </li>
            {!showRoute &&
              <li>
                <Link
                  to={route(routes.view_order_messages, [!showRoute ? id : notaryBid.orderId, selectedPerson?.schedulerFullName ? Message.CHANNEL_SCHEDULER_NOTARY : Message.CHANNEL_NOTARY_CUSTOMER])}
                  onClick={toggleDetailed}
                >
                  <div className="d-flex">
                    <div className="align-self-center me-3">
                      <img src={chatIcon} alt="chat" />
                    </div>

                    <div className="flex-grow-1 overflow-hidden my-auto">
                      <h5 className="contact-item-title text-truncate">
                        Chat
                      </h5>
                      <p className="contact-item-subtitle text-truncate">
                        Contact your {selectedPerson?.schedulerFullName ? "scheduler" : "customer"}
                      </p>
                    </div>
                  </div>
                </Link>
              </li>
            }
            {!showRoute &&
              <li>
                <Link to="" onClick={startVideoCall}>
                  <div className="d-flex">
                    <div className="align-self-center me-3">
                      <img src={videoIcon} alt="chat" />
                    </div>

                    <div className="flex-grow-1 overflow-hidden my-auto">
                      <h5 className="contact-item-title text-truncate">
                        Video call
                      </h5>
                    </div>
                  </div>
                </Link>
              </li>
            }
          </ul>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ContactDesktop;