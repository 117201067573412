/********** GET PROFILE **********/

export const GET_PROFILE = "GET_PROFILE"
export const GET_PROFILE_OK = "GET_PROFILE_OK"
export const GET_PROFILE_ERR = "GET_PROFILE_ERR"

/********** SAVE PROFILE **********/

export const AGREE_TO_PROFILE_TERMS = "AGREE_TO_PROFILE_TERMS"
export const SAVE_PROFILE_BILLING = "SAVE_PROFILE_BILLING"
export const SAVE_PROFILE_SHIPPING = "SAVE_PROFILE_SHIPPING"
export const SAVE_PROFILE_CONTACT = "SAVE_PROFILE_CONTACT"
export const SAVE_PROFILE_CREDENTIALS = "SAVE_PROFILE_CREDENTIALS"
export const SAVE_PROFILE_EXPERIENCE = "SAVE_PROFILE_EXPERIENCE"
export const SAVE_PROFILE_AREA = "SAVE_PROFILE_AREA"
export const SAVE_PROFILE_SHIPPING_AREA = "SAVE_PROFILE_SHIPPING_AREA"
export const SAVE_PROFILE_OK = "SAVE_PROFILE_OK"
export const SAVE_PROFILE_ERR = "SAVE_PROFILE_ERR"

export const DO_SAVE_PROFILE_CLEANUP = "DO_SAVE_PROFILE_CLEANUP"

export const RESET_SAVE_PROFILE_ACTION_FLAG = 'RESET_SAVE_PROFILE_ACTION_FLAG'

/********** UPDATE GLBA **********/

export const UPDATE_GLBA_GENERAL = "UPDATE_GLBA_GENERAL"
export const UPDATE_GLBA_GENERAL_OK = "UPDATE_GLBA_GENERAL_OK"
export const UPDATE_GLBA_GENERAL_ERR = "UPDATE_GLBA_GENERAL_ERR"
export const DO_UPDATE_GLBA_GENERAL_CLEANUP = "DO_UPDATE_GLBA_GENERAL_CLEANUP"

export const UPDATE_GLBA_SECURITY = "UPDATE_GLBA_SECURITY"
export const UPDATE_GLBA_SECURITY_OK = "UPDATE_GLBA_SECURITY_OK"
export const UPDATE_GLBA_SECURITY_ERR = "UPDATE_GLBA_SECURITY_ERR"
export const DO_UPDATE_GLBA_SECURITY_CLEANUP = "DO_UPDATE_GLBA_SECURITY_CLEANUP"

export const UPDATE_GLBA_ACCESS = "UPDATE_GLBA_ACCESS"
export const UPDATE_GLBA_ACCESS_OK = "UPDATE_GLBA_ACCESS_OK"
export const UPDATE_GLBA_ACCESS_ERR = "UPDATE_GLBA_ACCESS_ERR"
export const DO_UPDATE_GLBA_ACCESS_CLEANUP = "DO_UPDATE_GLBA_ACCESS_CLEANUP"

/********** CREATE GLBA **********/

export const CREATE_GLBA = "CREATE_GLBA"
export const CREATE_GLBA_OK = "CREATE_GLBA_OK"
export const CREATE_GLBA_ERR = "CREATE_GLBA_ERR"
export const DO_CREATE_GLBA_CLEANUP = "DO_CREATE_GLBA_CLEANUP"

/********** SAVE OUT OF OFFICE **********/

export const SAVE_OUT_OF_OFFICE = "SAVE_OUT_OF_OFFICE"
export const SAVE_OUT_OF_OFFICE_OK = "SAVE_OUT_OF_OFFICE_OK"
export const SAVE_OUT_OF_OFFICE_ERR = "SAVE_OUT_OF_OFFICE_ERR"
export const DO_SAVE_OUT_OF_OFFICE_CLEANUP = "DO_SAVE_OUT_OF_OFFICE_CLEANUP"