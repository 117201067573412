import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Card, CardBody, Button, Form, Input, FormFeedback, Label } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import Col from "components/Shared/Col";
import { useProfile } from "context/profile";
import { getSsoAppUrl, showError, showSuccess } from "helpers/utilHelper";
import useFirstRender from "hooks/firstRender";
import { agreeToProfileTerms, doSaveProfileCleanup, resetSaveProfileActionFlag } from "store/actions";
import { route, routes } from "helpers/routeHelper";
import useProfileTermsCompleteCheck from "hooks/profile/profileTermsCompleteCheck";
import TCSection from "pages/Terms/Partial/Section/TC";
import NDASection from "pages/Terms/Partial/Section/NDA";

const ProfileSetupTerms = () => {

  // check if the user has already agreed to terms
  // and if that is the case, navigate to the next step
  useProfileTermsCompleteCheck();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { notary, refreshProfile } = useProfile();
  const { isNotFirstRender } = useFirstRender();

  /********** STATE **********/

  // flag signaling that a profile context refresh is in progress
  const [isProfRefInProgress, setIsProfRefInProgress] = useState(false);

  // isSaveInProgress - TRUE if a save request is in progress, FALSE otherwise
  // saved - TRUE if a save was attempted and was successfull, FALSE if it failed, NULL if no save was attempted yet
  const { isSaveInProgress, saved } = useSelector(state => state.Profile.Form);

  /********** FORM CONFIG **********/

  const formInitialValues = {
    fullName: '',
    company: '',
    title: '',
    agreeTerms: false
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      fullName: Yup.string().trim().required('Field is required'),
      company: Yup.string().trim(),
      title: Yup.string().trim(),
      agreeTerms: Yup.bool().required('Field is required'),
    }),
    onSubmit: values => doAgreeToTerms(values),
  });

  /********** EFFECTS **********/

  useEffect(() => {
    return () => {
      // clear redux state on component unmount
      // so the other wizard steps start clean
      dispatch(doSaveProfileCleanup());
    }
  }, []);

  // runs after a save attempt
  useEffect(() => {
    if (saved === true) {
      showSuccess('Agreement saved');
      // since we have added new information to the profile
      // we have to reload the profile context
      // else the new info will not be available in the next steps
      doProfileRefresh();
    } else if (saved === false) {
      showError('Unable to save agreement');
    }
    if (saved !== null) {
      // we have to reset this flag once we consumed its value
      // else the next wizard step will show a notification on mount
      // because all wizard steps use the same redux state
      dispatch(resetSaveProfileActionFlag('saved'));
    }
  }, [saved]);

  // runs when the profile info (from context) changes
  // this happens twice:
  // 1. on the first render (null -> value)
  // 2. when the user clicks NEXT and the profile is updated (old value -> new value)
  // we are interested in #2
  useEffect(() => {
    if (isNotFirstRender) {
      // profile info has been reloaded so it is safe to navigate to the next step
      navigate(route(routes.setup_profile_billing));
    }
  }, [notary]);

  /********** OTHER **********/

  /**
   * Changes the in-progress flag and triggers a context refresh
   */
  const doProfileRefresh = () => {
    setIsProfRefInProgress(true);
    refreshProfile();
  }

  /**
   * Calls the backend to update the profile info with the terms agreement
   */
  const doAgreeToTerms = (values) => dispatch(agreeToProfileTerms(values));

  /**
   * Returns TRUE if a remote call is in progress
   * We use it to determine whether the submit button should show a loading icon
   * @returns bool
   */
  const isSubmitBusy = () => isSaveInProgress || isProfRefInProgress;

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  return <React.Fragment>
    <div className="page-content">
      {!!notary && <React.Fragment>
        <MetaTitle>Profile - Terms</MetaTitle>
        <Container fluid className="profile-wizard">
          <Breadcrumbs breadcrumbItems={breadcrumbs()} />
          <Row>
            <Col xl="12" xxxl="10" xxxxl="8">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <div className='terms-modal-content-small mb-4'>
                        <div className='terms-scroll-content'>
                          <NDASection />
                        </div>
                      </div>
                      <div className='terms-modal-content-small mb-4'>
                        <div className='terms-scroll-content'>
                          <TCSection />
                        </div>
                      </div>
                      <Form>
                        <div className='mt-4'>
                          <Row>
                            <Col>
                              <Row className="mb-4">
                                <Label className="col-sm-2 col-form-label">Full Name *</Label>
                                <Col sm={10}>
                                  <Input type="text" className="form-control" placeholder="Your name" name="fullName" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.fullName} invalid={!!formik.errors.fullName} />
                                  {!!formik.errors.fullName && <FormFeedback type="invalid">{formik.errors.fullName}</FormFeedback>}
                                </Col>
                              </Row>
                              <Row className="mb-4">
                                <Label className="col-sm-2 col-form-label">Company</Label>
                                <Col sm={10}>
                                  <Input type="text" className="form-control" placeholder="Company name" name="company" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.company} invalid={!!formik.errors.company} />
                                  {!!formik.errors.company && <FormFeedback type="invalid">{formik.errors.company}</FormFeedback>}
                                </Col>
                              </Row>
                              <Row className="mb-4">
                                <Label className="col-sm-2 col-form-label">Title</Label>
                                <Col sm={10}>
                                  <Input type="text" className="form-control" placeholder="Your title" name="title" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.title} invalid={!!formik.errors.title} />
                                  {!!formik.errors.title && <FormFeedback type="invalid">{formik.errors.title}</FormFeedback>}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-3">
                          <div className="d-flex">
                            <Input
                              type="checkbox"
                              id="agreeTerms"
                              name="agreeTerms"
                              className="form-check-input-lg me-2"
                              onChange={(e) => formik.setFieldValue("agreeTerms", e.target.checked)}
                            />
                            <Label htmlFor="agreeTerms">I have read and agree to the Terms & Conditions.</Label>
                          </div>
                          <div className="text-end">
                            <a href={getSsoAppUrl('logout')} className="btn btn-primary btn-faded">
                              <i className="mdi mdi-chevron-left me-1" />
                              Cancel
                            </a>
                            <Button type="button" color="primary" className="ms-2" onClick={formik.handleSubmit} disabled={isSubmitBusy() || !formik.values.agreeTerms}>
                              {isSubmitBusy() && <i className="mdi mdi-spin mdi-loading me-1" />}
                              {!isSubmitBusy() && <i className="mdi mdi-check me-1" />}
                              Agree
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>}
    </div>
  </React.Fragment>
}

const breadcrumbs = () => [{
  title: 'PROFILE',
}, {
  title: 'Signing Rules & Guidelines',
}];

export default ProfileSetupTerms;