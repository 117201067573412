import React, { useEffect, useState } from "react"
import TextareaAutosize from "react-textarea-autosize";
import NotaryBidCard from "./index";
import Confirmation from "components/Shared/Confirmation";
import { useSelector } from "react-redux";
import NotaryBid from "model/notaryBid";
import { acceptNotaryBid, declineNotaryBid } from "helpers/backendHelper";
import { getMiles, showBriefError, showError } from "helpers/utilHelper";
import NotaryBidCardConfirmed from "./Confirmed";
import NotaryBidCardDeclined from "./Declined";
import addressIncon from '../../../../assets/images/address-icon.svg';
import newPageIcon from 'assets/images/open-in-new-page.svg';
import { useNavigate } from "react-router-dom";
import { useProfile } from "../../../../context/profile";
import { Row, Form, Button, Label, Col, FormFeedback, Input } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { route, routes } from "../../../../helpers/routeHelper";
import schedulerIcon from "assets/images/scheduler-icon.svg";

const NotaryBidCardDetailed = () => {

  const navigate = useNavigate();
  const { notary } = useProfile();

  /********** STATE **********/

  const { notaryBid } = useSelector(state => state.NotaryBid.Single);
  const isAvailableBid = (notaryBid.status === NotaryBid.STATUS_NOTIFIED) && !notaryBid.isExpired;

  // UI state
  const [isDeclineConfirmationVisible, setIsDeclineConfirmationVisible] = useState(false);
  const [isAcceptConfirmationVisible, setIsAcceptConfirmationVisible] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [declined, setDeclined] = useState(false);

  // holds form values after validation and until the user accepts the confirmation
  const [tempFormValues, setTempFormValues] = useState();

  // State to track whether all required fields are filled
  const [allRequiredFieldsFilled, setAllRequiredFieldsFilled] = useState(false);

  /********** FORM CONFIG **********/

  const formInitialValues = {
    aoiPrint: !!notaryBid.agreedToTermsTs || '',
    collectCustomerThumbprint: !!notaryBid.agreedToTermsTs || '',
    notifyMavScheduler: !!notaryBid.agreedToTermsTs || '',
    requiredNotarizations: !!notaryBid.agreedToTermsTs || '',
    fullCompliance: !!notaryBid.agreedToTermsTs || '',
    criticalInSigningProcess: !!notaryBid.agreedToTermsTs || '',
    availability: notaryBid.availability || [],
    previewDocsAknowledgement: notaryBid.status === NotaryBid.STATUS_ACCEPTED_BY_NOTARY && notaryBid.mustUploadDocs || '',
    comments: notaryBid.comments || '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      availability: Yup.array().required("Field is required"),
      aoiPrint: Yup.bool().oneOf([true], "Field is required"),
      collectCustomerThumbprint: Yup.mixed().when([], {
        is: () => notaryBid.orderAoiThumbprintRequired,
        then: Yup.bool().oneOf([true], "Field is required"),
      }),
      notifyMavScheduler: Yup.bool().oneOf([true], "Field is required"),
      requiredNotarizations: Yup.bool().oneOf([true], "Field is required"),
      fullCompliance: Yup.bool().oneOf([true], "Field is required"),
      criticalInSigningProcess: Yup.bool().oneOf([true], "Field is required"),
      previewDocsAknowledgement: Yup.mixed().when([], {
        is: () => notaryBid.mustUploadDocs,
        then: Yup.bool().oneOf([true], "Field is required"),
      }),
    }),
    onSubmit: values => {
      setTempFormValues(values);
      setIsAcceptConfirmationVisible(true);
    },
  });

  /********** EFFECTS **********/

  // runs whenever the validation fails
  useEffect(() => {
    if (!formik.isValid) {
      showBriefError('Form has errors');
    }
  }, [formik.isValid]);

  /********** HANDLERS **********/

  // Function to check if all required fields are filled
  const checkAllRequiredFields = () => {

    const availability = !!formik.values.availability.length;

    const { aoiPrint, collectCustomerThumbprint, notifyMavScheduler, fullCompliance, previewDocsAknowledgement, criticalInSigningProcess, requiredNotarizations } = formik.values;

    const requiredFields = [aoiPrint, notifyMavScheduler, fullCompliance, availability, criticalInSigningProcess, requiredNotarizations];

    if (notaryBid.orderAoiThumbprintRequired) {
      requiredFields.push(collectCustomerThumbprint);
    }

    if (notaryBid.mustUploadDocs) {
      requiredFields.push(previewDocsAknowledgement);
    }

    const allFieldsFilled = requiredFields.every(field => field);

    setAllRequiredFieldsFilled(allFieldsFilled);
  };

  useEffect(() => {
    checkAllRequiredFields();
  }, [formik.values]);

  const declineBid = () => {
    declineNotaryBid(notaryBid.id, { comments: formik.values.comments })
      .then(() => {
        setDeclined(true);
      })
      .catch(() => {
        showError("Unable to decline lead")
      });
  };

  const acceptBid = () => {
    acceptNotaryBid(notaryBid.id, tempFormValues)
      .then(() => {
        setAccepted(true);
      })
      .catch(() => {
        showError("Unable to confirm lead");
        formik.setSubmitting(false);
      });
  };

  const setAvailability = (value) => {
    const option = parseInt(value);
    let availabilityList = formik.values.availability ? [...formik.values.availability] : [];
    if (!availabilityList.includes(option)) {
      availabilityList.push(option);
    } else {
      availabilityList.splice(availabilityList.indexOf(option), 1)
    }
    formik.setFieldValue('availability', availabilityList)
  }

  const openGoogleMaps = () => {
    const customerLocationLink = `https://www.google.com/maps/?q=${notaryBid.orderSignerLatitude},${notaryBid.orderSignerLongitude}`;
    window.open(customerLocationLink, "_blank")
  }

  if (accepted) return <NotaryBidCardConfirmed />

  if (declined) return <NotaryBidCardDeclined />

  return <NotaryBidCard notaryBid={notaryBid} isDetailed={true}>
    <div className="google-maps-distance my-1">
      <div>
        <div className="notary-bid-basic-info">
          <i className="bx bxs-map-pin" />
          <span>{`${getMiles(notaryBid.distance)} miles away`}</span>
        </div>
        <div className="mt-1">This is the straight line distance (air) and not road distance. For a more accurate distance, doublecheck it with Google Maps.</div>
      </div>
      <div className="view-in-map-btn">
        <Button type="button" color="primary" onClick={openGoogleMaps}>
          View on Google Maps
          <img className="ms-2" src={newPageIcon} />
        </Button>
      </div>
    </div>
    <Row className="mt-3">
      <Col xs={6} lg={3} className="mb-3 mb-lg-0">
        <div className="d-flex align-items-center mb-1">
          <i className="dripicons-location font-size-17 me-2" />
          <h6>Customer Address</h6>
        </div>
        <div className="mb-1">
          {notaryBid.orderSignerAddress}
        </div>
        <div className="mb-1">
          {`City: ${notaryBid.orderSignerCity}, State: ${notaryBid.orderSignerState}`}
        </div>
        <div className="mb-1">
          {`ZIP code: ${notaryBid.orderSignerZip}`}
        </div>
        {notaryBid.orderSignerAddress2 &&
          `Address info: ${notaryBid.orderSignerAddress2}`
        }
      </Col>
      <Col xs={6} lg={3}>
        <div className="d-flex align-items-center mb-1">
          <i className="dripicons-store font-size-17 me-2" />
          <h6>Dealership</h6>
        </div>
        <div className="mb-1">
          {notaryBid.dealerStoreName || "--"}
        </div>
        <div className="mb-1">
          {`State: ${notaryBid.dealerStoreState || "--"}`}
        </div>
      </Col>
      <Col xs={6} lg={3}>
        <div className="d-flex align-items-center mb-1">
          <i className="dripicons-document-new font-size-17 me-2" />
          <h6>Pages</h6>
        </div>
        <div className="mb-1">
          {`${notaryBid.numOfDocuments} Document(s)`}
        </div>
        <div className="mb-1">
          {`${notaryBid.numOfPages} Page(s)`}
        </div>
        <div>
          {`${notaryBid.numOfSignatures} Signature(s)`}
        </div>
      </Col>

    </Row>
    <div className="divider-faded" />
    <Form>
      <div>
        <h6 className='mb-2'>I can honor this signing within:</h6>
        <div className="form-check my-2">
          <input
            className="form-check-input"
            type="checkbox"
            name="today"
            id="today"
            value={NotaryBid.AVAILABILITY_TODAY}
            checked={formik.values.availability.includes(NotaryBid.AVAILABILITY_TODAY)}
            onChange={(e) => setAvailability(e.target.value)}
            disabled={!isAvailableBid}
          />
          <label className="form-check-label" htmlFor="today">24 hours</label>
        </div>
        {/* Display 48 hours option only for order without rush service */}
        {!notaryBid.orderRushRequired && <div className="form-check mb-2">
          <input
            className="form-check-input"
            type="checkbox"
            name="tomorrow"
            id="tomorrow"
            value={NotaryBid.AVAILABILITY_TOMORROW}
            checked={formik.values.availability.includes(NotaryBid.AVAILABILITY_TOMORROW)}
            onChange={(e) => setAvailability(e.target.value)}
            disabled={!isAvailableBid}
          />
          <label className="form-check-label" htmlFor="tomorrow">48 hours
          </label>
        </div>}
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            name="weekend"
            id="weekend"
            value={NotaryBid.AVAILABILITY_WEEKEND}
            checked={formik.values.availability.includes(NotaryBid.AVAILABILITY_WEEKEND)}
            onChange={(e) => setAvailability(e.target.value)}
            disabled={!isAvailableBid}
          />
          <label className="form-check-label" htmlFor="weekend">Over the weekend</label>
        </div>
      </div>
      <div className="divider-faded" />
      <div>
        <h6 className='mb-2'>Please confirm the following:</h6>
        <div className="form-check my-2">
          <Row className="mb-3">
            <div>
              <Input
                type="checkbox"
                id="aoiPrint"
                name="aoiPrint"
                className="form-check-input"
                checked={formik.values.aoiPrint}
                onChange={(e) => formik.setFieldValue('aoiPrint', e.target.checked)}
                disabled={!isAvailableBid}
              />
              <Label htmlFor="aoiPrint">I understand I am to print two MavSign Affidavits of Identity (AOI) for each customer. These will not be included in the Dealer documents.</Label>
              {!!formik.errors.aoiPrint && <FormFeedback type="invalid">{formik.errors.aoiPrint}</FormFeedback>}
            </div>
          </Row>
          {!!notaryBid.orderAoiThumbprintRequired && <Row className="mb-3">
            <div>
              <Input
                type="checkbox"
                id="collectCustomerThumbprint"
                name="collectCustomerThumbprint"
                className="form-check-input"
                checked={formik.values.collectCustomerThumbprint}
                onChange={(e) => formik.setFieldValue('collectCustomerThumbprint', e.target.checked)}
                disabled={!isAvailableBid}
              />
              <Label htmlFor="collectCustomerThumbprint">I will collect the customer’s thumbprint on the Mavsign AOI, unless instructed otherwise by my MavSign Scheduler.</Label>
              {!!formik.errors.collectCustomerThumbprint && <FormFeedback type="invalid">{formik.errors.collectCustomerThumbprint}</FormFeedback>}
            </div>
          </Row>}
          <Row className="mb-3">
            <div>
              <Input
                type="checkbox"
                id="notifyMavScheduler"
                className="form-check-input"
                name="notifyMavScheduler"
                onChange={(e) => formik.setFieldValue('notifyMavScheduler', e.target.checked)}
                checked={formik.values.notifyMavScheduler}
                disabled={!isAvailableBid}
              />
              <Label htmlFor="notifyMavScheduler">I understand that I will notify my MavSign Scheduler if the customer requests to change the signing location. I will not proceed with signing  until I receive approval from MavSign</Label>
              {!!formik.errors.notifyMavScheduler && <FormFeedback type="invalid">{formik.errors.notifyMavScheduler}</FormFeedback>}
            </div>
          </Row>
          <Row className="mb-3">
            <div>
              <Input
                type="checkbox"
                id="fullCompliance"
                className="form-check-input"
                name="fullCompliance"
                onChange={(e) => formik.setFieldValue('fullCompliance', e.target.checked)}
                checked={formik.values.fullCompliance}
                disabled={!isAvailableBid}
              />
              <Label htmlFor="fullCompliance">I confirm that I am in full compliance with all the regulations and credentials required by the state(s) in which I am commissioned.</Label>
              {!!formik.errors.fullCompliance && <FormFeedback type="invalid">{formik.errors.fullCompliance}</FormFeedback>}
            </div>
          </Row>
          <Row className="mb-3">
            <div>
              <Input
                type="checkbox"
                id="criticalInSigningProcess"
                className="form-check-input"
                name="criticalInSigningProcess"
                onChange={(e) => formik.setFieldValue('criticalInSigningProcess', e.target.checked)}
                checked={formik.values.criticalInSigningProcess}
                disabled={!isAvailableBid}
              />
              <Label htmlFor="criticalInSigningProcess">I understand that I am critical in the signing process. Without my reliability, the terms, rebates, funding, and delivery of the vehicle can be severely compromised and/or voided resulting in potential loss not only to Mavsign but the Dealership and their customers as well. I have considered my availability and ability before accepting.</Label>
              {!!formik.errors.criticalInSigningProcess && <FormFeedback type="invalid">{formik.errors.criticalInSigningProcess}</FormFeedback>}
            </div>
          </Row>
          <Row className="mb-3">
            <div>The fee for this signing is <span className='font-weight-semibold'>${notaryBid.notaryFee}</span></div>
          </Row>
          <h6 className='info-text mb-3'>Please note: If you want to request a different fee for this signing you will need to accept the lead and add notes with your increased fee, in the box provided. Selecting decline will remove your name from our assigning view, for that order.</h6>
        </div>
      </div>
      {notaryBid.mustUploadDocs && <React.Fragment>
        <div>
          <div className="form-check my-2">
            <Row className="mb-3">
              <div>
                <Input
                  type="checkbox"
                  id="previewDocsAknowledgement"
                  name="previewDocsAknowledgement"
                  className="form-check-input"
                  checked={formik.values.previewDocsAknowledgement}
                  onChange={(e) => formik.setFieldValue('previewDocsAknowledgement', e.target.checked)}
                  disabled={!isAvailableBid}
                />
                <Label htmlFor="previewDocsAknowledgement">I understand I will be uploading a copy of the signed documents to the order after the signing is completed.</Label>
                {!!formik.errors.previewDocsAknowledgement && <FormFeedback type="invalid">{formik.errors.previewDocsAknowledgement}</FormFeedback>}
              </div>
            </Row>
          </div>
        </div>
      </React.Fragment>}
      <div className="form-check my-2">
        <Row className="mb-3">
          <div>
            <Input
              type="checkbox"
              id="requiredNotarizations"
              className="form-check-input"
              name="requiredNotarizations"
              onChange={(e) => formik.setFieldValue('requiredNotarizations', e.target.checked)}
              checked={formik.values.requiredNotarizations}
              disabled={!isAvailableBid}
            />
            <Label htmlFor="requiredNotarizations">I understand as the notary I am responsible for completing all required notarizations, and collecting all customer signatures and initials.</Label>
            {!!formik.errors.requiredNotarizations && <FormFeedback type="invalid">{formik.errors.requiredNotarizations}</FormFeedback>}
          </div>
        </Row>
      </div>
      <div className="divider-faded" />
      <h6 className='mb-2'>Delivery Information:</h6>
      <Row>
        <Col xs={12}>
          <div className='d-flex align-items-center mb-2'>
            <img src={addressIncon} alt='address-icon' />
            <div className='font-weight-500 ms-2'>Documents Delivery Address</div>
          </div>
          <div className='info-text'>
            <div>{notary.shippingAddress}</div>
            <div><span className='font-weight-500'>City:</span> {notary.shippingCity}, State: {notary.shippingState}</div>
            <div>Zip code: {notary.shippingZip}</div>
          </div>
          <Button color="primary" className="mb-2 mt-3 rounded-lg" onClick={() => navigate(route(routes.view_profile))}>Update My Address</Button>
        </Col>
      </Row>
      <h6 className='info-text mt-3'>Please use the comments area below if the addresses are not correct.</h6>
      <div className="divider-faded" />
      <div className="mb-3">
        <h6 className='mb-2'>Comments:</h6>
        <TextareaAutosize
          maxRows={15}
          minRows={7}
          className="form-control"
          name="comments"
          onChange={formik.handleChange}
          value={formik.values.comments}
          disabled={!isAvailableBid}
        />
        {!!formik.errors.comments && <FormFeedback type="invalid">{formik.errors.comments}</FormFeedback>}
      </div>
    </Form>

    {isAvailableBid && <div className="text-center text-lg-end">
      <Button color="secondary" className="action-button me-2" onClick={() => setIsDeclineConfirmationVisible(true)}>Decline</Button>
      <Button type="button" color="primary" className="action-button"
        onClick={formik.handleSubmit}
        disabled={formik.isSubmitting || !allRequiredFieldsFilled}>
        Confirm
      </Button>
    </div>}

    {isDeclineConfirmationVisible && <Confirmation
      title="Decline this lead?"
      confirmBtnText="Decline"
      confirmBtnCssClass="notary-decline-lead me-2 button-alert"
      onConfirm={() => {
        setIsDeclineConfirmationVisible(false);
        declineBid();
      }}
      reverseButtons={false}
      onCancel={() => setIsDeclineConfirmationVisible(false)}>
      <span style={{ color: '#556EE6' }}>You are about to decline this lead.</span>
    </Confirmation>}

    {isAcceptConfirmationVisible && <Confirmation
      title={<h4 style={{ color: '#556EE6' }}>You are about to confirm this lead!</h4>}
      success
      confirmBtnText="Confirm"
      confirmBtnCssClass="notary-accept-lead me-2 button-alert"
      confirmBtnBsStyle="success"
      reverseButtons={false}
      style={{ backgroundColor: '#fff' }}
      onConfirm={() => {
        acceptBid();
        setIsAcceptConfirmationVisible(false);
        setTempFormValues(null);
      }}
      onCancel={() => {
        setTempFormValues(null);
        formik.setSubmitting(false);
        setIsAcceptConfirmationVisible(false);
      }}>
      <span style={{ color: '#556EE6' }}>Your confirmation will be reviewed by a scheduler. You will hear back from us shortly.</span>
    </Confirmation>}
  </NotaryBidCard>
}

export default NotaryBidCardDetailed;
