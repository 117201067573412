import React from "react";
import { Button, Col, Row } from "reactstrap";
import glbaIcon from "assets/images/glba-check.svg"
import { route, routes } from 'helpers/routeHelper';
import { useNavigate } from "react-router-dom";

const GlbaBanner = () => {
  // router hook that helps redirect
  const navigate = useNavigate();

  return <div className="glba-banner mb-3">
    <Row>
      <Col className="py-3" xs="6" lg="9">
        <div className="glba-banner-title">Complete Your GLBA Assessment Form</div>
        <div className="glba-banner-description">Ensure compliance and data security by completing <br/>this quick assessment directly on your profile.</div>
        <Button color="primary" onClick={() => navigate(route(routes.glba_general_information))}>Submit Assessment</Button>
      </Col>
      <Col xs="6" lg="3">
        <img src={glbaIcon} className="glba-icon" />
      </Col>
    </Row>
  </div>
}

export default GlbaBanner;

