import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Row, Form, Label, Col, Button, Card, CardHeader, CardBody, CardTitle, FormFeedback } from "reactstrap";
import removeIcon from "assets/images/remove-icon.svg";
import { showError, showSuccess } from "helpers/utilHelper";
import DatePicker from "components/Shared/DatePicker";
import TextareaAutosize from "react-textarea-autosize";
import { doSaveOutOfOfficeCleanup, saveOutOfOffice } from "store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { formats, formatTimestampUtc } from 'helpers/dateHelper';
import Confirmation from 'components/Shared/Confirmation';
import { nullsToEmptyStrings } from "helpers/utilHelper";
import moment from 'moment-timezone';

const FormEditOutOfOffice = (props) => {

  const { finishedHandler, defaultValues } = props;

  const dispatch = useDispatch();

  // get redux state from the store
  const { isSaveInProgress, saved } = useSelector((state) => state.Profile.OutOfOfficeForm);

  /********** STATE **********/
  const [currentInterval, setCurrentInterval] = useState({
    startTs: "",
    endTs: "",
    reason: "",
  });

  const [removeRowModal, setRemoveRowModal] = useState(false);
  const [modalDescription, setModalDescription] = useState();
  const [itemToRemove, setItemToRemove] = useState();

  /********** FORM CONFIG **********/
  // Validation schema for an individual interval
  const intervalSchema = Yup.object().shape({
    startTs: Yup.number().typeError('You have to select a valid start date.'),
    endTs: Yup.number().typeError('You have to select an valid end date.'),
    reason: Yup.string().trim().optional(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: {
      outOfOfficeIntervals: [],
      ...nullsToEmptyStrings({outOfOfficeIntervals: [...defaultValues]}),
    },
    onSubmit: values => {
      // Format the values to match the required payload structure (startTs, endTs, reason)
      // by removing unnecessary fields such as id, notaryId, and updatedTs.
      const transformedData = values.outOfOfficeIntervals.map(item => ({
        startTs: item.startTs,
        endTs: item.endTs,
        reason: item.reason
      }));
      dispatch(saveOutOfOffice({ outOfOfficeIntervals: transformedData }));
    }
  });


  /********** EFFECTS **********/
  useEffect(() => {
    return () => {
      dispatch(doSaveOutOfOfficeCleanup());
    };
  }, []);

  useEffect(() => {
    if (saved === true) {
      showSuccess("Out of Office data is saved");
      finishedHandler();
    } else if (saved === false) {
      showError("Unable to save Out of Office details");
    }
  }, [saved, finishedHandler]);

  /********** EVENT HANDLERS **********/
  const handleChange = (field, value) => {
    setCurrentInterval((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const isDuplicateDate = (date) => {
    // Extract only the date (YYYY-MM-DD) for comparison
    // and check if any existing interval has the same date, in order to not add the same start date again
    const formattedDate = moment.unix(date).utc().format("YYYY-MM-DD");
    return formik.values.outOfOfficeIntervals.some(
      (i) => moment.unix(i.startTs).utc().format("YYYY-MM-DD") === formattedDate
    );
  };

  const handleAddInterval = async () => {

    // Ensure both start date and end date are selected before adding the interval
    if (!currentInterval.startTs || !currentInterval.endTs) {
      return showError("Start date and end date must be selected.");
    }

    // Ensure to not add a new interval with the same start day
    if (isDuplicateDate(currentInterval.startTs)) {
      return showError("This start date already exists. Please choose another date.");
    }

    if (currentInterval.startTs > currentInterval.endTs) {
      return showError("Start date must be before the end date.");
    }

    try {
      // Validate the interval before adding it
      await intervalSchema.validate(currentInterval);

      // Update Formik state with the new interval
      formik.setValues({
        ...formik.values,
        outOfOfficeIntervals: [...formik.values.outOfOfficeIntervals, currentInterval],
      });

      // Reset the input fields after adding the interval
      setCurrentInterval({ startTs: "", endTs: "", reason: "" });

    } catch (error) {
      showError(error.message);
    }
  };

  const handleRemoveInterval = () => {
    formik.setFieldValue(
      "outOfOfficeIntervals",
      formik.values.outOfOfficeIntervals.filter(interval => interval.startTs !== itemToRemove)
    );
    setRemoveRowModal(false);
  };

  const handeleShowModal = (startTs, endTs) => {
    setModalDescription(
      <>
        Are you sure you want to delete this Out of Office entry for <br />
        <div className="font-weight-700">
          {formatTimestampUtc(startTs, formats.FULL_MONTH_DATE)} <br />-{" "}
          {formatTimestampUtc(endTs, formats.FULL_MONTH_DATE)}?
        </div>
         This action cannot be undone.
      </>
    );
    setRemoveRowModal(true);
    setItemToRemove(startTs);
  }

  return (
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <CardTitle className="mb-0">Out of Office Dates</CardTitle>
          </Col>
          <Col xs="auto">
            <div className="text-end">
              <Button type="button" color="primary" onClick={formik.handleSubmit} disabled={isSaveInProgress} >
              {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}Save All</Button>
              <Button type="button" color="secondary" className="ms-2" onClick={finishedHandler}>Cancel</Button>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Form>
          <Row>
            <Col>
              <Label className="col-form-label">Start Date</Label>
              <DatePicker
                name="startTs"
                value={currentInterval.startTs}
                onChange={(date) => handleChange("startTs", date)}
                minDate={new Date().setHours(0, 0, 0, 0)}
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <Label>End Date</Label>
              <DatePicker
                name="endTs"
                value={currentInterval.endTs}
                onChange={(date) => handleChange("endTs", date)}
                minDate={new Date().setHours(0, 0, 0, 0)}
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <Label>Reason</Label>
              <TextareaAutosize
                minRows={4}
                className="form-control"
                name="reason"
                value={currentInterval.reason}
                onChange={(e) => handleChange("reason", e.target.value)}
              />
            </Col>
          </Row>

          <div className="text-end mb-4">
            <Button type="button" color="primary" className="mt-3" onClick={handleAddInterval}>
              Add Out of Office Dates
            </Button>
          </div>
        </Form>
        <>
          { formik.values.outOfOfficeIntervals.length === 0 ? <p>No intervals added yet.</p> :
            formik.values.outOfOfficeIntervals.map((interval, index) => <div key={index} className="out-of-office-box mb-3">
              <div className="d-flex justify-content-between">
                <div>{formatTimestampUtc(interval.startTs, formats.FULL_MONTH_DATE)} - {formatTimestampUtc(interval.endTs, formats.FULL_MONTH_DATE)}</div>
                <div className="text-end cursor-pointer" onClick={() => handeleShowModal(interval.startTs, interval.endTs)}><img src={removeIcon} alt="remove-icon" /></div>
              </div>
              {
                !!interval.reason && <div className="mt-3">
                  <div className="font-weight-semibold mb-2">Reason</div>
                  <div>{interval.reason}</div>
                </div>
              }
            </div>)
          }
        </>
      </CardBody>
      {/* Remove Row - Modal */}
      {removeRowModal && <Confirmation
        style={{ backgroundColor: 'white', width: 400 }}
        onConfirm={() => handleRemoveInterval()}
        onCancel={() => setRemoveRowModal(false)}
        closeOnClickOutside={false}
        reverseButtons={false}
      >
        <div className="d-flex justify-content-center">
          <Col sm={9}>
            <span style={{ color: '#556EE6', fontSize: 15}}>{modalDescription}</span>
          </Col>
        </div>
      </Confirmation>}
    </Card>
  );
};

FormEditOutOfOffice.propTypes = {
  finishedHandler: PropTypes.func.isRequired,
  defaultValues: PropTypes.array,
};

export default FormEditOutOfOffice;
