import React, { useEffect } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { useParams, useLocation } from "react-router-dom";
import { matchPath } from "react-router";
import { routes } from 'helpers/routeHelper';
import ContactDesktop from "./Desktop";
import ContactMobile from "./Mobile";
import { useDispatch, useSelector } from "react-redux";
import { getOrder } from "store/actions";
import { getNotaryBid } from "store/actions";
import { showError } from "helpers/utilHelper";

const Contact = () => {
  // get order id from url
  let { id } = useParams();
  id = parseInt(id);

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  const location = useLocation();

  const { order, orderError } = useSelector(state => state.Order.Single);
  const { notaryBid } = useSelector(state => state.NotaryBid.Single);

  useEffect(() => {
    const showRoute = !!matchPath({ path: routes.view_notary_bid }, location.pathname);
    if (showRoute) {
      !!notaryBid &&
        dispatch(getNotaryBid(id))
    }
    else { dispatch(getOrder(id)) };
  }, [id]);

  useEffect(() => {
    if (orderError) {
      showError("Unable to contact");
    }
  }, [orderError]);

  return (
    <React.Fragment>
      {(order || notaryBid) && (
        <>
          <BrowserView>
            <ContactDesktop />
          </BrowserView>
          <MobileView>
            <ContactMobile />
          </MobileView>
        </>
      )}
    </React.Fragment>
  );
};

export default Contact;