import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import SectionGlbaAssessment from "components/Shared/GlbaAssessment/View";
import SectionGlbaBanner from "components/Shared/GlbaAssessment/Banner";
import SectionBilling from "../Partial/Section/Billing";
import SectionShipping from "../Partial/Section/Shipping";
import SectionContact from "../Partial/Section/Contact";
import SectionExperience from "../Partial/Section/Experience";
import SectionCredentials from "../Partial/Section/Credentials";
import SectionCredentialsDetails from "../Partial/Section/CredentialsDetails";
import SectionAccounting from "../Partial/Section/Accounting";
import SectionOutOfOffice from "../Partial/Section/OutOfOffice";
import { useProfile } from "context/profile";
import { getNotaryGlba } from "helpers/backendHelper";
import { showError } from "helpers/utilHelper";

const ViewIndex = () => {

  const { notary } = useProfile();

  /********** STATE **********/
  const [glba, setGlba] = useState({});

  const isGlbaCompleted = !!glba?.glbaAcceptedTs;

  /********** OTHER **********/
  const onGetGlba = () => {
    getNotaryGlba(notary?.glba?.id)
      .then(resp => setGlba(resp.notaryGlba))
      .catch((err) => {
        showError("Unable to load Glba Assessment data");
      })
  }

  /********** EFFECTS **********/
  // runs once on component mount
  useEffect(() => {
    if(notary?.glba) onGetGlba();
  }, []);

  return <div className="page-content">
    {notary && <>
      <MetaTitle>My Profile</MetaTitle>
      <Container fluid className='notary-profile'>
        <Breadcrumbs breadcrumbItems={breadcrumbs} />
        {!isGlbaCompleted && <Row><Col><SectionGlbaBanner /></Col></Row>}
        <Row>
          <Col xs={12} md={6}>
            <SectionBilling />
          </Col>
          <Col xs={12} md={6}>
            <SectionShipping />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <SectionContact />
          </Col>
          <Col xs={12} md={6}>
            <SectionExperience />
          </Col>
        </Row>
        {isGlbaCompleted && <Row><Col><SectionGlbaAssessment /></Col></Row>}
        <Row>
          <Col xs={12} md={8}>
            <SectionCredentials />
          </Col>
          <Col xs={12} md={4}>
            <SectionCredentialsDetails />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8}>
            <SectionAccounting />
          </Col>
          <Col xs={12} md={4}>
            <SectionOutOfOffice />
          </Col>
        </Row>
      </Container>
    </>}
  </div>
}

const breadcrumbs = [{
  title: "NOTARY PROFILE",
}, {
  title: "Edit profile",
}];

export default ViewIndex;