import React from "react";

const TCSection = () => {
  return <React.Fragment>
    <div>
      <div className="terms-title mb-3">Notary Service Agreement</div>
      <div className="terms-title">Recitals</div>
      <div className="terms-section">
        <p>
          {`Maverick Document Signings “Maverick” offers a suite of signing and identification verification platforms
                that are used separately or in conjunction with each other when dealing with customers that are outside
                of the dealership to sign automotive contracts and documents. Maverick recognizes that identity
                services provided by Notary is one level of fraud mitigation. The sophistication of fraudulent
                identification cards and synthetic identity improve each year. Therefore, Notary is hired in
                conjunction with Maverick’s and Dealer’s identity theft prevention program as required by the Federal
                Trade Commission’s Red Flag rules under the Fair Credit Reporting Act (FCRA) and Fair and Accurate
                Credit Transactions Act (FACTA) because no single product or service on its own, or together, can prevent
                identity fraud from occurring.`}
        </p>
      </div>
      <div className="terms-title">Notary Requirements</div>
      <div className="terms-section">
        <p>{`Maverick requires notaries to maintain their credentials through their notary account on the Maverick
                website. Maverick will select a notary based on whether: their state issued notary license or commission
                is current and active; their liability insurance and bond, if applicable, is current; have passed a
                background check verifying the notary’s social security identification and criminal history, including but
                not limited to the Nationwide Criminal Database and National Sex Offender Registry, the USA Patriot Act
                and Terrorist Watch List, and State Motor Vehicle Report.`}
        </p>
      </div>
      <div className="terms-title">Invitation to Accept Signing Orders</div>
      <div className="terms-section">
        <p>{`“Leads” are invitations to accept signing orders. Notary may be invited to accept a signing order
              through their Maverick account (a “lead”). Maverick may send a lead to multiple Notaries at
              once. The first Notary to accept the lead will be awarded the signing order and will extinguish
              all other leads. Once a lead is accepted, the signing order will be viewable in the Notary’s
              online Maverick Notary account. Leads expire after 45 minutes from being sent by Maverick. If a lead is
              not accepted by any Notary, Maverick may resend the lead to other Notaries. Maverick
              may also contact a Notary regarding the lead. Maverick does not guarantee Notary
              assignments by maintaining a notary account. Factors considered when inviting Notary to
              accept a notary assignment are: Maverick notary status; reputation for professionalism; thoroughness;
              availability; location and distance from Dealer’s customer; fees and costs; and other operational factors.`}
        </p>
      </div>
      <div className="terms-title">Services Provided</div>
      <div className="terms-section">
        <p>
          {`Upon acceptance of the signing order, Notary will be provided Dealer’s customer contact
                information to schedule a signing appointment. Notary shall contact Dealer’s customer the
                same day Notary receives Dealer’s documents. In the event Notary receives Dealer’s
                documents after 8:30pm, Notary will contact Dealer’s customer as soon as possible the next
                morning to schedule a signing appointment. Notary will immediately inform Maverick of their
                attempts to contact Dealer’s customer via phone call, text message, or email. Failure to contact Dealer’s
                customer within the first 24 hours of receiving Dealer’s documents may result in a negative rating in
                Notary’s notary profile.`}
        </p>
        <p>
          {`Notary will notify Maverick when Notary has confirmed the date, time, and location of
              the scheduled appointment with Dealer’s customer by updating the signing order in their notary profile.`}
        </p>
        <p>{`If a scheduled appointment date, time, or location was not provided by Dealer, Notary will set a time,
              date, and location with Dealer’s customer at a mutually agreeable time. Notary will inform
              Dealer’s customer that customer will need to provide two forms of identification, a thumb print on two
              copies of the Affidavit of Identity, and describe any other items to be collected at the time of the signing
              appointment. Notary will be notified if Dealer expressly waives certain identity verification
              services.`}
        </p>
        <p>
          {`Notary will travel to the signing location to witness Dealer’s customer signing of Dealer’s
              documents. Notary will have Dealer’s customer sign or initial all required lines clearly marked
              by Dealer wherever Dealer requires customer’s signatures, initials or acknowledgements in accordance
              with Dealer’s checklist. Notary will collect two notarized copies of the Affidavit of Identity, all
              documents provided by Dealer, the notarized forms, funds, a digital photograph of customer’s driver’s
              license or state issued identification card, and all other pertinent items requested by Dealer to complete
              the signing order. Notary will place one Affidavit of Identity and the other collected items in the
              pre-addressed, pre-paid return envelope provided by Dealer. All overnight packages will be dropped off
              for delivery to Dealer the same day if the signing takes place prior to 3:00 P.M. in the time zone where
              the signing takes place and a suitable drop off location is available. Notaries will make best efforts to ship
              overnight packages the same day back to Dealer when the signing takes place after 3:00 P.M.`}
        </p>
      </div>
      <div className="terms-title">Shipping Costs for Documents</div>
      <div className="terms-section">
        <p>{`All shipping costs are the responsibility of Dealer, however in the event a return envelope is not
              provided, Maverick will pay for the overnight shipping.`}
        </p>
      </div>
      <div className="terms-title">Change in Signing</div>
      <div className="terms-section">
        <p>{`Notary shall sign the documents at the signing location provided by Dealer. In the event Dealer
              customer asks to change the signing location, Notary must advise the assigned Maverick
              scheduler immediately. Maverick will notify Dealer of the customer’s request to change the appointment
              location and will ask Dealer to approve or reject the location change in writing. If Dealer provides written
              consent to the change the signing location, then Notary will process the signing at the new
              location chosen by the customer.`}
        </p>
      </div>
      <div className="terms-title">Missing signatures</div>
      <div className="terms-section">
        <p>{`Notary takes responsibility for missing signatures, initials, or notarizations. Notary will
              correct any missed signatures by meeting with the customer to obtain the missed signatures, initials, or
              notarizations and return the required documents to Dealer within 24-48 hours, depending on the
              customer’s availability at no additional cost to Maverick. Maverick will pay for the shipping of the
              documents. Missed signatures will be confirmed by a Maverick scheduler by reviewing Dealer’s
              completed checklist against a copy of Dealer’s documents that were clearly marked to match the
              Dealer’s checklist. Maverick will provide Notary a list of documents with the missing signatures,
              initials, or notarizations. If a missing signature is due to a Dealer error, such as an incomplete Dealer
              checklist, or Dealer documents that were not included in the original document package, any fees and
              shipping costs associated with the notary having to return to the customer will be the sole responsibility
              of the Dealer.`}
        </p>
      </div>
      <div className="terms-title">Answering Customer’s Questions About Dealer’s Documents</div>
      <div className="terms-section">
        <p>{`Notary will not review Dealer’s paperwork to determine accuracy, sufficiency, or completeness.
              Notary will make no statement to Dealer’s customer in regards to Dealer’s documents. In the
              event Dealer’s customer has a question regarding Dealer’s documents, Notary will assist
              Dealer’s customer to call Dealer so that their question may be answered. In the event Notary
              has a question regarding the placement of a signature or date, they may contact the Dealer for
              clarification. Notary will not strike through any portion of dealer documents. Instead, Notary
              shall leave the portion of the document as-is and place a note on the document and write why
              the portion of the document was left blank. For example, if a portion needed correction by Dealer, or
              was refused by Dealer’s customer. Notary will leave the note in the document package and
              inform Maverick immediately after the signing appointment of the unsigned portions to explain why the
              document was left blank.`}
        </p>
      </div>
      <div className="terms-title">Customer Verification Criteria</div>
      <div className="terms-section">
        <p>{`In order to assist in the verification of Dealer’s customer’s identity, Notary is responsible for
              collecting digital photographs of customer’s government issued identification at the time of the signing
              appointment. The government issued picture identification must contain a photo of the customer.
              Notary may also be required to collect customer’s proof of residency. Maverick will inform
              Notary whether a proof of residency is required for a signing order on a case-by-case basis. All
              documents must be current and unexpired and all documents must be originals. Temporary and/or
              paper licenses (or other limited variations of driver’s licenses) will not be accepted unless Dealer
              authorizes Maverick or Notary to accept it. These documents are required solely for customer
              identification purposes.`}
        </p>
        <p>{`Notary will examine the customer’s signatures for substantial similarity to the customer’s
              government issued identification card. However, neither Maverick nor Notary are responsible
              for detecting acts of fraud on the part of the Dealer or the Dealer’s customer including the use of fake or
              fraudulent picture, non-picture identification, or signature irregularities. Neither Maverick nor Notary
              make any representation of their ability to identify forged signatures nor do they make any
              representation or guarantee that the signatures the customer provided are authentic. If Notary
              determines, in their own professional judgment, that the authenticity of the customer’s government
              issued identification or signature is in question, then Notary will inform Maverick immediately
              after the signing appointment. Notary will inform Maverick after the signing appointment that
              it suspects Dealer’s customer has failed to provide an acceptable form of identification if any of the
              following occurs: customer has provided a form of identification which is suspect; customer has failed to
              sign Dealer’s documents substantially similarly to their signature on the identification card; customer
              refuses to provide a thumbprint on the Affidavit of Identity; or customer fails, or refuses to, provide
              sufficient proof of identity. In the event Dealer’s customer completely fails identification, for example, by
              failing to provide identification documents as required or requested by Dealer, Notary will terminate the
              signing process and Notary will return Dealer’s documents to Dealer unsigned and
              uncompleted.`}
        </p>
      </div>
      <div className="terms-title">Performance</div>
      <div className="terms-section">
        <p>{`Notary agrees to perform its services in a good and professional manner. Maverick will notify
              Dealer immediately Notary suspects fraud, forgery, identity theft, or if Dealer’s customer
              refuses to provide the identification required or requested. While Notary shall exercise due diligence and prudence in carrying through the document signing process, neither Maverick nor Notary have any duty to assess any transaction for possible fraud or other crime by Dealer’s customer.`}
        </p>
      </div>
      <div className="terms-title">Notary Fee and Payment</div>
      <div className="terms-section">
        <p>{`Notary will be paid according to Maverick’s most current notary rate net 30 days after the
              completed signing with upload of the affidavit of identity and copies of customer’s identification. Proof
              of receipts is required for reimbursements of parking or toll fees; or printing fees. If the signing order
              requires additional vehicles to be signed; additional time spent at the signing; computer scanning or
              uploading; rural or out of area travel; additional trips to customer; or additional notarizations Notary
              will be paid according to the current Notary Fee Schedule. Notary will be paid the full
              fee regardless of Dealer’s and customer’s relationship after a completed signing. Subsequent signing
              appointments due to Notary’s missed signatures, or unclear affidavit of identity will not be
              compensated. All billing inquiries or changes to billing address should be sent to billing@mavsign.com.
              All payments due to Notary shall be paid in US currency.`}
        </p>
      </div>
      <div className="terms-title">Customer Late Arrivals and No-Shows</div>
      <div className="terms-section">
        <p>{`In the event a customer does not show up to the pre-arranged signing appointment, Notary will
              be compensated according to the current Notary Fee Schedule. Notary shall make good faith
              attempts to reach the customer for an estimated arrival time if the customer is late to a signing
              appointment and will wait for a reasonable amount of time before canceling a signing appointment.`}
        </p>
      </div>
      <div className="terms-title">Independent Contractor</div>
      <div className="terms-section">
        <p>{`It is expressly agreed that Notary is acting as an independent contractor and not as an
              employee of Maverick. Notary and Maverick also agree that these services in no way create any
              partnership or joint venture between them, and Notary is merely performing notary signing
              and identity verification services on behalf of Maverick to sign Dealer’s documents.`}
        </p>
      </div>
      <div className="terms-title">Confidentiality</div>
      <div className="terms-section">
        <p>{`Notary shall keep confidential and proprietary information regarding Dealer’s business. Notary
            acknowledges that Dealer’s documents and customer identification documents contain
            personally identifiable information, sensitive information, financial information, and may include
            biometric identification. Notary warrants to Maverick that it shall maintain customer’s privacy
            and shall comply with any and all applicable privacy laws and policies regarding the collection and
            storage of private personal information during the signing process.`}
        </p>
      </div>
      <div className="terms-title">Commitment to Safeguards under the Gramm-Leach-Bliley Act</div>
      <div className="terms-section">
        <p>{`The “Safeguards Rule” of the Gramm-Leach-Bliley Act (“GLBA”) requires Dealers, as a financial
                institution, to (1) take reasonable steps to select and retain Notaries that are capable of
                maintaining appropriate safeguards for customer information; (2) require Notary by contract to
                implement and maintain such safeguards; and (3) periodically assess Notary’s continued
                adequacy of such safeguards based on the risks presented. Notary acknowledges that Maverick
                is subject to the GLBA by Dealer’s obligation to implement and maintain reasonable security procedures
                and practices to protect customer information, and has the responsibility to safeguard customer information that flows from Maverick to Notary. Notary Agrees to data security and
                compliance under the GLBA’s Safeguards Rule. In light of the foregoing, Notary agrees to
                implement and maintain reasonable security measures that are sufficient to meet the Dealer's
                obligations under the Safeguards Rule.`}
        </p>
        <p>{`Notary shall take reasonable steps to protect customer information from unauthorized access,
                disclosure, alteration, or destruction. Maverick recognizes that the reasonable steps standard allows for
                flexibility and should be tailored to Notary’s size, scope of services, and the nature of the data
                involved. Maverick may assess the adequacy of the Notary’s safeguards periodically provided
                that Notary agrees that such assessments will not be unduly intrusive or disruptive to the
                Notary's normal operations; will take place during normal business hours; will relate solely to
                the scope of the reasonable security measures required under the Safeguards Rule; and relating only to
                information shared with Notary by Maverick and Dealer. Maverick shall provide at least ten (10)
                days’ notice prior to conducting any such audit of Notary’s reasonable security measures. Both
                parties agree to cooperate fully in good faith to resolve any issues or concerns related to data security
                and compliance with the Safeguards Rule, including the timely sharing of relevant information and
                documentation.`}
        </p>
      </div>
      <div className="terms-title">Governing Law</div>
      <div className="terms-section">
        <p>{`The performance of the services requested by Dealer and all suits or special proceedings brought arising
              out of or related to the services provided by Maverick shall be construed in accordance with and
              governed by the laws of the State of California. The sole and exclusive venue for any dispute arising out
              of or related to the services provided by Maverick shall be in the state and federal courts located in and
              for Orange County, California and Dealer hereby irrevocably consents to the jurisdiction of said courts.
              The prevailing party in any court proceeding or arbitration hereunder shall be entitled to recover their
              reasonable attorney’s fees.`}
        </p>
      </div>
    </div>
  </React.Fragment>
}

export default TCSection;