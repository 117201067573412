import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col, CardHeader, Button, CardTitle, Table } from "reactstrap";
import CardPreloader from "components/Shared/CardPreloader";
import { Link } from "react-router-dom";
import { useProfile } from "context/profile";
import { perms, useAccess } from "context/access";
import { decimalToDMS, getBeUrl } from "../../../helpers/utilHelper";
import { formatTimestamp, formats } from "helpers/dateHelper";
import { route, routes } from "helpers/routeHelper";
import pdfIcon from 'assets/images/pdf-icon.svg';

const ViewContact = ({ isRefreshing, toggleEditMode }) => {

  const { notary } = useProfile();

  const latitudeVal = decimalToDMS(notary.shippingLatitude, notary.shippingLatitude);
  const longitudeVal = decimalToDMS(notary.shippingLongitude);

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  const downloadTCFile = () => getBeUrl(`/notary/${notary.id}/terms-agreement/download`);

  return (<Card className="expand-v">
    <CardHeader className="bg-transparent pt-3 pb-0">
      <Row>
        <Col>
          <CardTitle>Contact Information</CardTitle>
        </Col>
        <Col xs="auto">
          <div className="text-end">
            {iAmGranted(perms.edit_notaries) && <Button type="button" color="primary" className="mb-2" onClick={toggleEditMode}>
              <i className="mdi mdi-pencil me-1" />Edit
            </Button>}
          </div>
        </Col>
      </Row>
    </CardHeader>
    <CardBody>
      <Table className="section-rows table bt-1">
        <tbody>
          <tr>
            <td className="section-row-label">Phone</td>
            <td className="section-row-value ps-4">{notary.phone || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label">Email Address</td>
            <td className="section-row-value ps-4">{notary.email || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label">Website</td>
            <td className="section-row-value ps-4">{notary.website || "---"}</td>
          </tr>
          <tr>
            <td className="section-row-label">Google map</td>
            <td className="section-row-value ps-4">
              <a className='text-decoration-underline' href={`https://www.google.com/maps/place/${latitudeVal},${longitudeVal}`}>
                {latitudeVal} {longitudeVal}
              </a>
            </td>
          </tr>
          <tr>
            <td className="section-row-label no-border">T&C and NDA agreement</td>
            <td className="section-row-value ps-4 no-border fw-normal">
              <Link to={route(routes.view_terms)} state={{ isRedirect: true }}>
                <Button outline color="primary" className="terms-btn">NDA and T&C</Button>
              </Link>
              <div className="mt-2">{formatTimestamp(notary.termsAcceptedTs, formats.DATE_PT_FORMAT)}</div>
              {
                !!notary.hasSignedTermsAgreementFile &&
                <a href={downloadTCFile()} target="_blank" rel="noreferrer" className="d-inline-block mt-2">
                  <img src={pdfIcon} /> {`${notary.userFullName}_${formatTimestamp(notary.termsAcceptedTs, formats.US_DATE)}_${notary.termsVersion}`}
                </a>
              }
            </td>
          </tr>
        </tbody>
      </Table>
    </CardBody>
    {isRefreshing && <CardPreloader />}
  </Card>)
}

ViewContact.propTypes = {
  isRefreshing: PropTypes.bool,
  toggleEditMode: PropTypes.func,
};

export default ViewContact;