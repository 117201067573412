import React, { useState, useEffect } from "react";
import ViewOutOfOffice from "pages/Profile/View/OutOfOffice";
import FormEditOutOfOffice from "pages/Profile/Partial/Form/OutOfOffice";
import { getOutOfOffice } from "helpers/backendHelper";
import { useProfile } from "context/profile";
import { useSelector } from "react-redux";
import { showError } from "helpers/utilHelper";

const SectionOutOfOffice = () => {

  const { notary } = useProfile();
  const { isSaveInProgress } = useSelector((state) => state.Profile.OutOfOfficeForm);

  /********** STATES **********/
  const[outOfOfficeDates, setOutOfOfficeDates] = useState([]);

  // toggle edit-in-place on/off
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  /********** HANDLERS **********/
  const getOutOfOfficeDates = () => {
    getOutOfOffice(notary.id)
      .then(resp => setOutOfOfficeDates(resp))
      .catch(err => showError("Unable to load out of office data."))
  }

  // toggles the edit mode on and off
  const toggleEditMode = doRefresh => {
    setIsEditModeActive(prevMode => !prevMode);
    // see if the data has changed and we need to refresh (ex. after a successful save)
    if (doRefresh === true) {
      setIsRefreshing(true);
    }
  }

  /********** EFFECTS **********/
  useEffect(() => {
    if (!isSaveInProgress) {
      setIsRefreshing(false);
    }
    getOutOfOfficeDates();
  }, [isSaveInProgress]);

  useEffect(() => {
    getOutOfOfficeDates();
  },[])

  return <React.Fragment>
    {isEditModeActive && <FormEditOutOfOffice defaultValues={outOfOfficeDates} finishedHandler={toggleEditMode} />}
    {!isEditModeActive && <ViewOutOfOffice defaultValues={outOfOfficeDates} toggleEditMode={toggleEditMode} isRefreshing={isRefreshing} />}
  </React.Fragment>
}

export default SectionOutOfOffice;