import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col, CardHeader, Button, CardTitle } from "reactstrap";
import CardPreloader from "components/Shared/CardPreloader";
import { formats, formatTimestampUtc } from 'helpers/dateHelper';


const ViewOutOfOffice = ({ isRefreshing, toggleEditMode, defaultValues }) => {

  return (<Card className="expand-v">
    <CardHeader className="bg-transparent pt-3 pb-0">
      <Row>
        <Col>
          <CardTitle className="mb-0">Out of Office Dates</CardTitle>
        </Col>
        <Col xs="auto">
          <div className="text-end">
            <Button type="button" color="primary" onClick={toggleEditMode}>
              <i className="mdi mdi-pencil me-1" />Edit
            </Button>
          </div>
        </Col>
      </Row>
    </CardHeader>
    <CardBody>
      {defaultValues.length === 0 ? (
        <p>No intervals added yet.</p>
      ) : (
        defaultValues.map((interval, index) => <div key={index} className="out-of-office-box mb-3">
          <div>{formatTimestampUtc(interval.startTs, formats.FULL_MONTH_DATE)} - {formatTimestampUtc(interval.endTs, formats.FULL_MONTH_DATE)}</div>
          {!!interval.reason && <div className="mt-3">
            <div className="font-weight-semibold mb-2">Reason</div>
            <div>{interval.reason}</div>
          </div>}
        </div>)
      )}
    </CardBody>
    {isRefreshing && <CardPreloader />}
  </Card>)
}

ViewOutOfOffice.propTypes = {
  isRefreshing: PropTypes.bool,
  toggleEditMode: PropTypes.func,
  defaultValues: PropTypes.array,
};

export default ViewOutOfOffice;