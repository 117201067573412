import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import NotaryGlba from 'model/notaryGlba';
import { nullsToEmptyStrings, showBriefSuccess, showError } from "helpers/utilHelper";
import { useNavigate } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import { useDispatch, useSelector } from "react-redux";
import { Row, Form, Button, Label, Input, FormFeedback } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Col from "components/Shared/Col";
import { updateGlbaAccess, doUpdateGlbaAccessCleanup } from "store/actions";
import { ValidationException } from "helpers/errorHelper";
import classnames from "classnames";

const FormAccessRetentionPolicies = props => {
  const { defaultValues, navRoutes } = props;

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // router hook that helps redirect
  const navigate = useNavigate();

  /********** STATE **********/
  const [nextRoute, setNextRoute] = useState('');
  const [submitCount, setSubmitCount] = useState(0);

  const isNoChecked = (value) => value === false || value === 0;

  // get redux state from the store
  const { isSaveAccessInProgress, savedAccess, savedAccessError } = useSelector(state => state.Profile.GlbaForm);

  /********** FORM CONFIG **********/

  const formInitialValues = {
    hasRestrictedAccess: '',
    customerInfoDisposal: '',
    customerInfoDisposalDetails: '',
    customerInfoStoragePeriod: '',
    customerInfoStoragePeriodDetails: '',
    customerInfoStoragePeriodRequirement: '',
    hasExperiencedSecurityEvent: '',
    glbaAcceptedTs: '',
    ...nullsToEmptyStrings(defaultValues),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: !!submitCount, // We count the submits, so we can validate onChange only after the first submit, in this way we don't interfere with the user until he presses submit
    validateOnBlur: true,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      customerInfoStoragePeriodRequirement: Yup.string().trim().required('Field is required'),
      customerInfoStoragePeriod: Yup.mixed().required("Please choose one value"),
      glbaAcceptedTs: Yup.mixed().required("Please choose one value"),
      hasExperiencedSecurityEvent: Yup.mixed().required("Please choose one value"),
      hasRestrictedAccess: Yup.mixed().required("Please choose one value"),
      customerInfoDisposal: Yup.mixed().required("Please choose one value"),
      customerInfoStoragePeriodDetails: Yup.string()
        .when('customerInfoStoragePeriod', {
          is: (customerInfoStoragePeriod) => +customerInfoStoragePeriod === NotaryGlba.CUSTOMER_INFO_STORAGE_PERIOD_OTHER,
          then: Yup.string().required('Please provide details for "Other".'),
          otherwise: Yup.string().nullable(),
        }),
      customerInfoDisposalDetails: Yup.string().trim()
        .when('customerInfoDisposal', {
          is: (customerInfoDisposal) => +customerInfoDisposal === NotaryGlba.CUSTOMER_INFO_DISPOSAL_OTHER,
          then: Yup.string().required('Please provide details for "Other".'),
          otherwise: Yup.string().nullable(),
        }),
    }),
    onSubmit: values => dispatch(updateGlbaAccess(defaultValues.id, values)),
  });

  /********** EFFECTS **********/
  // runs whenever the 'saved' flag changes
  useEffect(() => {
    if (savedAccess === true) {

      showBriefSuccess('Security Practices has been saved');

      dispatch(doUpdateGlbaAccessCleanup());

      navigate(route(nextRoute));

    } else if (savedAccess === false) {

      showError('Unable to save Access and Retention Policies');

      //see if the save failed due to validation
      if (savedAccessError instanceof ValidationException) {
        // show an error on each invalid field
        for (const [name, message] of Object.entries(savedAccessError.fields)) {
          formik.setFieldError(name, message);
        }
      }
      // enable the save button
      formik.setSubmitting(false);
    }
  }, [savedAccess]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }


  return <React.Fragment>
    <Form className="pt-4">
      <div className="card-section">
        <Row>
          <Col lg="6">
            <div className="mb-4">
              <h6 className='mb-2'>Does Notary authenticate and permit access only to authorized users to access customer information?</h6>
              {/* Option: Yes | No */}
              <div className={classnames({ 'is-invalid': !!formik.errors.hasRestrictedAccess }, 'form-check my-2')}>
                <Input
                  className="form-check-input"
                  type="radio"
                  id="isRestrictedAccess"
                  name="hasRestrictedAccess"
                  value={true}
                  checked={formik.values.hasRestrictedAccess}
                  onChange={() => formik.setFieldValue('hasRestrictedAccess', true)}
                />
                <Label className="form-check-label" htmlFor="isRestrictedAccess">Yes</Label>
              </div>

              <div className={classnames({ 'is-invalid': !!formik.errors.hasRestrictedAccess }, 'form-check my-2')}>
                <input
                  className="form-check-input"
                  type="radio"
                  id="no"
                  name="noRestrictedAccess"
                  value={false}
                  checked={isNoChecked(formik.values.hasRestrictedAccess)}
                  onChange={() => formik.setFieldValue('hasRestrictedAccess', false)}
                />
                <Label className="form-check-label" htmlFor="noRestrictedAccess">No</Label>
              </div>
              {!!formik.errors.hasRestrictedAccess && <FormFeedback>{formik.errors.hasRestrictedAccess}</FormFeedback>}
            </div>

            <div className="mb-4">
              <h6 className='mb-2'>How long does Notary store customer information?</h6>
              {/* Option: Less than one year */}
              <div className={classnames({ 'is-invalid': !!formik.errors.customerInfoStoragePeriod }, 'form-check my-2')}>
                <input
                  className="form-check-input"
                  type="radio"
                  id="oneYear"
                  name="customerInfoStoragePeriod"
                  value={NotaryGlba.CUSTOMER_INFO_STORAGE_PERIOD_ONE_YEAR}
                  checked={+formik.values.customerInfoStoragePeriod === NotaryGlba.CUSTOMER_INFO_STORAGE_PERIOD_ONE_YEAR}
                  onChange={() => formik.setFieldValue('customerInfoStoragePeriod', NotaryGlba.CUSTOMER_INFO_STORAGE_PERIOD_ONE_YEAR)}
                />
                <Label className="form-check-label" htmlFor="oneYear">Less than one year</Label>
              </div>
              {/* Option: Less than two years */}
              <div className={classnames({ 'is-invalid': !!formik.errors.customerInfoStoragePeriod }, 'form-check my-2')}>
                <input
                  className="form-check-input"
                  type="radio"
                  id="lessThanTwoYears"
                  name="customerInfoStoragePeriod"
                  value={NotaryGlba.CUSTOMER_INFO_STORAGE_PERIOD_LESS_THAN_TWO_YEARS}
                  checked={+formik.values.customerInfoStoragePeriod === NotaryGlba.CUSTOMER_INFO_STORAGE_PERIOD_LESS_THAN_TWO_YEARS}
                  onChange={(e) => formik.setFieldValue('customerInfoStoragePeriod', e.target.value)}
                />
                <Label className="form-check-label" htmlFor="lessThanTwoYears">Less than two years</Label>
              </div>
              {/* Option: More than two years */}
              <div className={classnames({ 'is-invalid': !!formik.errors.customerInfoStoragePeriod }, 'form-check my-2')}>
                <input
                  className="form-check-input"
                  type="radio"
                  id="moreThanTwoYears"
                  name="customerInfoStoragePeriod"
                  value={NotaryGlba.CUSTOMER_INFO_STORAGE_PERIOD_MORE_THAN_TWO_YEARS}
                  checked={+formik.values.customerInfoStoragePeriod === NotaryGlba.CUSTOMER_INFO_STORAGE_PERIOD_MORE_THAN_TWO_YEARS}
                  onChange={(e) => formik.setFieldValue('customerInfoStoragePeriod', e.target.value)}
                />
                <Label className="form-check-label" htmlFor="moreThanTwoYears">More than two years</Label>
              </div>
              {/* Option: Other */}
              <div className={classnames({ 'is-invalid': !!formik.errors.customerInfoStoragePeriod }, 'form-check my-2')}>
                <input
                  className="form-check-input"
                  type="radio"
                  id="otherPeriod"
                  name="customerInfoStoragePeriod"
                  value={NotaryGlba.CUSTOMER_INFO_STORAGE_PERIOD_OTHER}
                  checked={+formik.values.customerInfoStoragePeriod === NotaryGlba.CUSTOMER_INFO_STORAGE_PERIOD_OTHER}
                  onChange={(e) => formik.setFieldValue('customerInfoStoragePeriod', e.target.value)}
                />
                <label className="form-check-label" htmlFor="otherPeriod">Other</label>
              </div>
              {/* Conditional Input for Other Period */}
              {+formik.values.customerInfoStoragePeriod === NotaryGlba.CUSTOMER_INFO_STORAGE_PERIOD_OTHER && (<>
                <Input
                  className={classnames('form-control mt-3', { 'is-invalid': !!formik.errors.authorizedRepresentative })}
                  placeholder="Explain"
                  type="text"
                  name="customerInfoStoragePeriodDetails"
                  onChange={formik.handleChange}
                  onFocus={onFieldFocused}
                  value={formik.values.customerInfoStoragePeriodDetails}
                  invalid={!!formik.errors.customerInfoStoragePeriodDetails}
                />
                {formik.errors.customerInfoStoragePeriodDetails && (<FormFeedback type="invalid">{formik.errors.customerInfoStoragePeriodDetails}</FormFeedback>)}
              </>
              )}

              {!!formik.errors.customerInfoStoragePeriod && <FormFeedback>{formik.errors.customerInfoStoragePeriod}</FormFeedback>}
            </div>
            {/* Option: Yes | No */}
            <div className="mb-4">
              <h6 className='mb-2'>Has Notary experienced a data breach or security event in the past year?</h6>
              <div className={classnames({ 'is-invalid': !!formik.errors.hasExperiencedSecurityEvent }, 'form-check my-2')}>
                <input
                  className="form-check-input"
                  type="radio"
                  id="hasExperiencedSecurity"
                  name="hasExperiencedSecurityEvent"
                  value={true}
                  checked={formik.values.hasExperiencedSecurityEvent}
                  onChange={() => formik.setFieldValue('hasExperiencedSecurityEvent', true)}
                />
                <label className="form-check-label" htmlFor="hasExperiencedSecurity">Yes</label>
              </div>

              <div className={classnames({ 'is-invalid': !!formik.errors.hasExperiencedSecurityEvent }, 'form-check my-2')}>
                <input
                  className="form-check-input"
                  type="radio"
                  id="noExperiencedSecurity"
                  name="hasExperiencedSecurityEvent"
                  value={false}
                  checked={isNoChecked(formik.values.hasExperiencedSecurityEvent)}
                  onChange={() => formik.setFieldValue('hasExperiencedSecurityEvent', false)}
                />
                <label className="form-check-label" htmlFor="noExperiencedSecurity">No</label>
              </div>
              {!!formik.errors.hasExperiencedSecurityEvent && <FormFeedback>{formik.errors.hasExperiencedSecurityEvent}</FormFeedback>}
            </div>
          </Col>

          <Col lg="6">
            <div className="mb-4">
              <h6 className='mb-2'>Does Notary dispose of, or destroy, customer information after its legitimate business or lawful purpose?</h6>
              {/* Option: Yes */}
              <div className={classnames({ 'is-invalid': !!formik.errors.customerInfoDisposal }, 'form-check my-2')}>
                <input
                  className="form-check-input"
                  type="radio"
                  id="selfInfoDisposal"
                  name="customerInfoDisposal"
                  value={NotaryGlba.CUSTOMER_INFO_DISPOSAL_YES}
                  checked={+formik.values.customerInfoDisposal === NotaryGlba.CUSTOMER_INFO_DISPOSAL_YES}
                  onChange={(e) => formik.setFieldValue('customerInfoDisposal', e.target.value)}
                />
                <Label className="form-check-label" htmlFor="selfInfoDisposal">Yes</Label>
              </div>
              {/* Option: No */}
              <div className={classnames({ 'is-invalid': !!formik.errors.customerInfoDisposal }, 'form-check my-2')}>
                <input
                  className="form-check-input"
                  type="radio"
                  id="noInfoDisposal"
                  name="customerInfoDisposal"
                  value={NotaryGlba.CUSTOMER_INFO_DISPOSAL_NO}
                  checked={+formik.values.customerInfoDisposal === NotaryGlba.CUSTOMER_INFO_DISPOSAL_NO}
                  onChange={(e) => formik.setFieldValue('customerInfoDisposal', e.target.value)}
                />
                <Label className="form-check-label" htmlFor="noInfoDisposal">No</Label>
              </div>
              {/* Option: Other */}
              <div className='form-check my-2'>
                <input
                  className="form-check-input"
                  type="radio"
                  id="otherInfoDisposal"
                  name="customerInfoDisposal"
                  value={NotaryGlba.CUSTOMER_INFO_DISPOSAL_OTHER}
                  checked={+formik.values.customerInfoDisposal === NotaryGlba.CUSTOMER_INFO_DISPOSAL_OTHER}
                  onChange={(e) => formik.setFieldValue('customerInfoDisposal', e.target.value)}
                />
                <label className="form-check-label" htmlFor="otherInfoDisposal">Other</label>
              </div>
              {/* Conditional Input for customer Info Disposal Details */}
              {+formik.values.customerInfoDisposal === NotaryGlba.CUSTOMER_INFO_DISPOSAL_OTHER && (<>
                <Input
                  className={classnames('form-control mt-3', { 'is-invalid': !!formik.errors.authorizedRepresentative })}
                  placeholder="Explain"
                  type="text"
                  name="customerInfoDisposalDetails"
                  onChange={formik.handleChange}
                  onFocus={onFieldFocused}
                  value={formik.values.customerInfoDisposalDetails}
                  invalid={!!formik.errors.customerInfoDisposalDetails}
                />
                {formik.errors.customerInfoDisposalDetails && (<FormFeedback type="invalid">{formik.errors.customerInfoDisposalDetails}</FormFeedback>)}
              </>)}
              {!!formik.errors.hasExperiencedSecurityEvent && <FormFeedback>{formik.errors.customerInfoDisposal}</FormFeedback>}
            </div>

            <div className="mb-4">
              <h6 className='mb-2'>If Notary is a state commissioned notary, how long does the state require Notary to hold onto notarized customer information?</h6>
              <input
                className={classnames('form-control mt-3', { 'is-invalid': !!formik.errors.customerInfoStoragePeriodRequirement })}
                placeholder="Explain"
                type="text"
                name="customerInfoStoragePeriodRequirement"
                onChange={formik.handleChange}
                onFocus={onFieldFocused}
                value={formik.values.customerInfoStoragePeriodRequirement}
                invalid={!!formik.errors.customerInfoStoragePeriodRequirement}
              />
              {!!formik.errors.customerInfoStoragePeriodRequirement && <FormFeedback>{formik.errors.customerInfoStoragePeriodRequirement}</FormFeedback>}
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="form-check form-switch form-switch-lg d-flex align-items-center">
              <Input
                type="checkbox"
                className="form-check-input mt-0"
                id="termsAndConditions"
                name="glbaAcceptedTs"
                disabled={formik.values.glbaAcceptedTs}
                checked={!!formik.values.glbaAcceptedTs}
                value={formik.values.glbaAcceptedTs || ''}
                onChange={e => {
                  const isChecked = e.target.checked;
                  formik.setFieldValue(
                    'glbaAcceptedTs',
                    isChecked ? Math.floor(Date.now() / 1000) : null
                  );
                }}
                invalid={!!formik.errors.glbaAcceptedTs}
              />
              <Label
                className="form-check-label font-size-14 terms-of-service-label"
                htmlFor="termsAndConditions"
              >
                Notary Agrees to data security and compliance under the Gramm-Leach-Bliley Act (“GLBA”) “Safeguards Rule.” <br />
                Notary agrees to implement and maintain reasonable security measures that are sufficient to meet Mavsign’s obligations under the Safeguards Rule.
              </Label>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="text-end pt-4">
            <Button type="button" color="primary" className="ms-2 btn-faded" onClick={() => { setNextRoute(navRoutes.prev); formik.handleSubmit() }} disabled={formik.isSubmitting}>
              {isSaveAccessInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
              Previous
            </Button>
            <Button type="button" color="primary" className="ms-2" onClick={() => { setNextRoute(route(routes.home)); formik.handleSubmit(); setSubmitCount(prev => ++prev) }} disabled={formik.isSubmitting}>
              {isSaveAccessInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
              Submit
            </Button>
          </Col>
        </Row>
      </div>
    </Form >
  </React.Fragment >
}

FormAccessRetentionPolicies.propTypes = {
  id: PropTypes.number,
  defaultValues: PropTypes.object,
  navRoutes: PropTypes.object,
};

export default FormAccessRetentionPolicies;