import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Container, Row, Card, CardBody, CardHeader } from "reactstrap";
import MetaTitle from "components/Shared/MetaTitle";
import Col from "components/Shared/Col";
import BackButton from "components/Shared/BackButton";
import FormSteps from "components/Shared/GlbaAssessment/Steps";
import FormAccessRetentionPolicies from "components/Shared/GlbaAssessment/Form/AccessRetentionPolicies";
import { getProfile } from "store/actions";
import { useProfile } from "context/profile";

const AccessRetentionPolicies = () => {

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  /********** STATE **********/
  // next/prev routes used for wizard navigation
  const [navRoutes, setNavRoutes] = useState({ next: null, prev: null });

  const { notary } = useProfile();

  /********** EFFECTS **********/
  // runs once on component mount
  useEffect(() => {
    refreshProfile();
  }, [navRoutes]);

  /********** OTHER **********/
  const refreshProfile = () => dispatch(getProfile());

  return <React.Fragment>
    <div className="page-content">
      <MetaTitle>GLBA Assessment Form - Access Retention Policies</MetaTitle>
      <Container fluid className='glba-assessment-form'>
        <BackButton />
        <Row>
          <Col>
            <Card>
              <CardHeader className="bg-transparent pt-3 pb-0">
                <Row>
                  <Col>
                    <div className="card-title pt-1 pb-4 mb-0">GLBA Assessment Form</div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <FormSteps currentStep="policies" setNavRoutes={setNavRoutes} />
                <FormAccessRetentionPolicies defaultValues={notary?.glba} navRoutes={navRoutes} />
                <div className="w-75 mt-2">
                  For additional details: <a href="https://www.fdic.gov/regulations/compliance/manual/8/viii-1.1.pdf" target="_blank" rel="noreferrer">https://www.fdic.gov/regulations/compliance/manual/8/viii-1.1.pdf</a>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
}

export default AccessRetentionPolicies;