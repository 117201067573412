import {
  GET_PROFILE,
  GET_PROFILE_OK,
  GET_PROFILE_ERR,

  AGREE_TO_PROFILE_TERMS,

  SAVE_PROFILE_BILLING,
  SAVE_PROFILE_SHIPPING,
  SAVE_PROFILE_CONTACT,
  SAVE_PROFILE_CREDENTIALS,
  SAVE_PROFILE_EXPERIENCE,
  SAVE_PROFILE_AREA,
  SAVE_PROFILE_SHIPPING_AREA,
  SAVE_PROFILE_OK,
  SAVE_PROFILE_ERR,
  DO_SAVE_PROFILE_CLEANUP,
  RESET_SAVE_PROFILE_ACTION_FLAG,
  SAVE_OUT_OF_OFFICE_ERR,
  SAVE_OUT_OF_OFFICE_OK,
  SAVE_OUT_OF_OFFICE,
  DO_SAVE_OUT_OF_OFFICE_CLEANUP,

  UPDATE_GLBA_GENERAL,
  UPDATE_GLBA_GENERAL_OK,
  UPDATE_GLBA_GENERAL_ERR,
  DO_UPDATE_GLBA_GENERAL_CLEANUP,

  UPDATE_GLBA_SECURITY,
  UPDATE_GLBA_SECURITY_OK,
  UPDATE_GLBA_SECURITY_ERR,
  DO_UPDATE_GLBA_SECURITY_CLEANUP,

  UPDATE_GLBA_ACCESS,
  UPDATE_GLBA_ACCESS_OK,
  UPDATE_GLBA_ACCESS_ERR,
  DO_UPDATE_GLBA_ACCESS_CLEANUP,

  CREATE_GLBA,
  CREATE_GLBA_OK,
  CREATE_GLBA_ERR,
  DO_CREATE_GLBA_CLEANUP,

} from './actionTypes';

/********** GET PROFILE **********/

export const getProfile = () => ({
  type: GET_PROFILE,
});

export const getProfileOk = response => ({
  type: GET_PROFILE_OK,
  payload: { response },
});

export const getProfileErr = error => ({
  type: GET_PROFILE_ERR,
  payload: { error },
});

/********** SAVE PROFILE **********/

export const agreeToProfileTerms = data => ({
  type: AGREE_TO_PROFILE_TERMS,
  payload: { data }
});

export const saveProfileBilling = data => ({
  type: SAVE_PROFILE_BILLING,
  payload: { data },
});

export const saveProfileShipping = data => ({
  type: SAVE_PROFILE_SHIPPING,
  payload: { data },
});

export const saveProfileContact = data => ({
  type: SAVE_PROFILE_CONTACT,
  payload: { data },
});

export const saveProfileCredentials = data => ({
  type: SAVE_PROFILE_CREDENTIALS,
  payload: { data },
});

export const saveProfileExperience = data => ({
  type: SAVE_PROFILE_EXPERIENCE,
  payload: { data },
});

export const saveProfileArea = data => ({
  type: SAVE_PROFILE_AREA,
  payload: { data },
});

export const saveProfileShippingArea = data => ({
  type: SAVE_PROFILE_SHIPPING_AREA,
  payload: { data },
});

export const saveProfileOk = response => ({
  type: SAVE_PROFILE_OK,
  payload: { response },
});

export const saveProfileErr = error => ({
  type: SAVE_PROFILE_ERR,
  payload: { error },
});

export const doSaveProfileCleanup = () => ({
  type: DO_SAVE_PROFILE_CLEANUP,
});

export const resetSaveProfileActionFlag = flag => ({
  type: RESET_SAVE_PROFILE_ACTION_FLAG,
  payload: { flag },
});

/********** SAVE OUT OF OFFICE **********/

export const saveOutOfOffice = data => ({
  type: SAVE_OUT_OF_OFFICE,
  payload: { data },
});

export const saveOutOfOfficeOk = data => ({
  type: SAVE_OUT_OF_OFFICE_OK,
  payload: { data },
});

export const saveOutOfOfficeErr = data => ({
  type: SAVE_OUT_OF_OFFICE_ERR,
  payload: { data },
});

export const doSaveOutOfOfficeCleanup = () => ({
  type: DO_SAVE_OUT_OF_OFFICE_CLEANUP,
});

/********** UPDATE GLBA **********/

export const updateGlbaGeneral = (id, data) => ({
  type: UPDATE_GLBA_GENERAL,
  payload: { id, data }
});

export const updateGlbaGeneralOk = response => ({
  type: UPDATE_GLBA_GENERAL_OK,
  payload: { response },
});

export const updateGlbaGeneralErr = error => ({
  type: UPDATE_GLBA_GENERAL_ERR,
  payload: { error },
});

export const doUpdateGlbaGeneralCleanup = () => ({
  type: DO_UPDATE_GLBA_GENERAL_CLEANUP,
});

export const updateGlbaSecurity = (id, data) => ({
  type: UPDATE_GLBA_SECURITY,
  payload: { id, data }
});

export const updateGlbaSecurityOk = response => ({
  type: UPDATE_GLBA_SECURITY_OK,
  payload: { response },
});

export const updateGlbaSecurityErr = error => ({
  type: UPDATE_GLBA_SECURITY_ERR,
  payload: { error },
});

export const doUpdateGlbaSecurityCleanup = () => ({
  type: DO_UPDATE_GLBA_SECURITY_CLEANUP,
});

export const updateGlbaAccess = (id, data) => ({
  type: UPDATE_GLBA_ACCESS,
  payload: { id, data }
});

export const updateGlbaAccessOk = response => ({
  type: UPDATE_GLBA_ACCESS_OK,
  payload: { response },
});

export const updateGlbaAccessErr = error => ({
  type: UPDATE_GLBA_ACCESS_ERR,
  payload: { error },
});

export const doUpdateGlbaAccessCleanup = () => ({
  type: DO_UPDATE_GLBA_ACCESS_CLEANUP,
});


/********** CREATE GLBA **********/

export const createGlba = (id, data) => ({
  type: CREATE_GLBA,
  payload: { id, data }
});

export const createGlbaOk = response => ({
  type: CREATE_GLBA_OK,
  payload: { response },
});

export const createGlbaErr = error => ({
  type: CREATE_GLBA_ERR,
  payload: { error },
});

export const doCreateGlbaCleanup = () => ({
  type: DO_CREATE_GLBA_CLEANUP,
});
