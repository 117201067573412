import React, { useEffect } from "react";
import { Container, Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import AccessDenied from "pages/Error/AccessDenied";
import Error from "pages/Error";
import Preloader from "components/Shared/Preloader";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { perms, useAccess } from "context/access";
import { getOrder } from "store/actions";
import { route, routes } from "helpers/routeHelper";
import OrderFlowAccordion from "../Partial/Flow/Accordion";
import { completeOrder } from "helpers/backendHelper";
import { showError, showSuccess } from "helpers/utilHelper";
import Order from "../../../model/order";
import OrderNotary from "model/orderNotary";
import { BrowserView, MobileView } from "react-device-detect";
import CardHeaderDesktop from "pages/Order/Partial/OrderCard/Desktop/Header";
import CardHeaderMobile from "pages/Order/Partial/OrderCard/Mobile/Header";
import OrderServicesSection from "components/Shared/OrderServicesSection";
import InfoMessage from "components/Shared/InfoMessage";

const OrderFlow = () => {

  let { id } = useParams();
  id = parseInt(id);

  // router hook that helps redirect
  const navigate = useNavigate();

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  // hook that checks permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  const { order, isLoadInProgress, orderError } = useSelector(state => state.Order.Single);

  const { docs: inkSignDocs } = useSelector(state => state.Order.InkSignDocs);

  const allDocsReturned = inkSignDocs?.every((item) => !!item.returnedByNotaryTs);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    refreshOrder();
  }, [id]);

  /********** HANDLERS **********/

  const complete = () => {
    completeOrder(order.orderNotaryId)
      .then(() => {
        showSuccess("Order completed");
        refreshOrder();
        navigate(route(routes.view_order, id));
      })
      .catch(() => {
        showError("Unable to complete order");
      });
  };

  /********** OTHER **********/

  const refreshOrder = () => dispatch(getOrder(id));

  const isReadOnly = () => order.status == Order.STATUS_CANCELLED || order.orderNotaryStatus == OrderNotary.STATUS_ORDER_COMPLETE;

  const isOrderGenerateLabel = () => order.status === Order.STATUS_GENERATE_SHIPPING_LABEL;

  return <React.Fragment>
    {iAmGranted(perms.view_orders) && <div className="page-content order-flow">
      {order && !order.orderNotaryIsInactive && !isOrderGenerateLabel() && <React.Fragment>
        <MetaTitle>Order #{id}</MetaTitle>
        <Container fluid>
          <Breadcrumbs breadcrumbItems={breadcrumbs(order)} />
          <Row>
            <Col xs={12}>
              {/* Desktop header */}
              <BrowserView>
                <CardHeaderDesktop order={order} />
              </BrowserView>
              {/* Mobile header */}
              <MobileView>
                <CardHeaderMobile order={order} />
              </MobileView>
              <OrderServicesSection className="mt-2" order={order} />
              <InfoMessage className="mt-3" alert description={OrderNotary.MESSAGE_MUST_CONFIRM_STEPS} />
              <OrderFlowAccordion order={order} />

              <div className="text-end">
                <div id="continue-btn-wrapper">
                  <Button color="primary" className="mav-card-action-btn mb-4 mt-0" onClick={complete} disabled={!allDocsReturned || isReadOnly()}>Complete</Button>
                </div>
                {!allDocsReturned && !isReadOnly() && <UncontrolledTooltip placement="top" target="continue-btn-wrapper">You will be able to complete the order as soon as all documents are marked as shipped</UncontrolledTooltip>}
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !order && <Preloader className="inner" />}
      {orderError && <Error error={orderError} title404="Order not found" />}
    </div>}
    {(iAmNotGranted(perms.view_orders) || (order && order.orderNotaryIsInactive) || (!!order && isOrderGenerateLabel())) && < AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = order => [{
  title: order.orderSignerFullName,
  url: route(routes.view_order, order.id),
}, {
  title: "Signing Flow",
}];

export default OrderFlow;