import PropTypes from 'prop-types';
import React from "react";

import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { matchPath } from "react-router";

// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import NotificationDropdown from 'components/CommonForBoth/TopbarDropdown/NotificationDropdown';
import Contact from 'components/CommonForBoth/TopbarDropdown/Contact';

import logoSimple from "assets/images/logo-simple-white.svg";

// Redux Store
import {
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";
import ImpersonationAlert from 'components/CommonForBoth/TopbarDropdown/ImpersonationAlert';
import { useAuth } from 'context/auth';
import { getUserOwner } from 'helpers/utilHelper';
import { routes } from 'helpers/routeHelper';
import HelpIcon from 'components/CommonForBoth/TopbarDropdown/HelpIcon';

const Header = () => {

  const { user: authUser } = useAuth();
  const owner = getUserOwner(authUser);

  const location = useLocation();

  // only show call button in order screens
  const showContactButton = !!matchPath({ path: routes.view_order }, location.pathname) || !!matchPath({ path: routes.view_order_flow }, location.pathname) || !!matchPath({ path: routes.view_notary_bid }, location.pathname);

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">

            <div className="navbar-brand-box d-lg-none d-md-block px-3">
              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSimple} alt="" height="25" className='logo-size-33' />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
            <h4 className="top-heading">{owner.name}</h4>
          </div>
          <div className="d-flex">
            {showContactButton && <Contact />}
            <ImpersonationAlert />
            <HelpIcon />
            <NotificationDropdown />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const {
    layoutType,
    leftMenu,
    leftSideBarType,
  } = state.Layout;
  return { layoutType, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  toggleLeftmenu,
  changeSidebarType,
})(Header);
