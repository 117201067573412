import React from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { route, routes } from "helpers/routeHelper";
import Message from "model/message";
import PrintAllDocs from "components/Shared/PrintAllDocs";
import Order from "model/order";

const OrderDetailedButtons = ({ order }) => {

  const navigate = useNavigate();

  return (<React.Fragment>
    <PrintAllDocs id={order.id} />
    <Button
      color="success"
      className="mav-card-action-btn d-flex justify-content-center"
      onClick={() => navigate(route(routes.view_order_messages, [order.id, Message.CHANNEL_SCHEDULER_NOTARY]))}
    >
      <i className="bx bx-message font-size-20 me-2" />
      Messages
    </Button>
    <span id="start-order-button" className="mav-card-action-btn d-flex align-items-center justify-content-center mt-0">
      <Button color="primary" className="mav-card-action-btn d-flex align-items-center justify-content-center" disabled={order.status === Order.STATUS_GENERATE_SHIPPING_LABEL} onClick={() => navigate(route(routes.view_order_flow, order.id))} >Start</Button>
    </span>
    {
      order.status === Order.STATUS_GENERATE_SHIPPING_LABEL &&
      <UncontrolledTooltip placement="top" target='start-order-button'>You cannot start the order unless the documents have been sent your way</UncontrolledTooltip>
    }
  </React.Fragment>)
}

OrderDetailedButtons.propTypes = {
  order: PropTypes.object,
};

export default OrderDetailedButtons;