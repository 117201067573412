import React from "react";
import PropTypes from "prop-types";
import downloadDoc from "assets/images/download-doc.png";
import signatureIcon from "assets/images/signature-icon.svg";
import notarizedIcon from "assets/images/notarized-badge.svg";
import { getBeUrl } from "helpers/utilHelper";
import { useAuth } from "context/auth";

const DocumentExpectedSection = ({ order, inkSignDocs, isDeliveryOptionUploaded, downloadBtnClassName, canBeDownload }) => {
  const { user } = useAuth();

  return <><h6 className="mt-3 mb-3">Document Expected</h6>
    {/* Ink sign Documents */}
    {inkSignDocs?.filter(inkDoc => inkDoc.creatorId !== user.id && !inkDoc.isStdDoc).map((inkDoc, index) => <div key={index} className='mobile-row-doc-wrapper'>
      <div className="mobile-row-doc-header">
        <img src={downloadDoc} alt="download-document" />
        <span className="mobile-row-doc-title">{inkDoc.name}</span>
        {!!inkDoc.isNotarizationRequired && <span className="notarized-badge ms-3">
          to be notarized <img src={notarizedIcon} alt='notarized-icon' />
        </span>}
      </div>
      <div className="mobile-row-doc-body">
        {!!inkDoc.signingInstructions && <>
          <h6 className='instructions-title'>Signing instructions</h6>
          <div className='instructions-description'>{inkDoc.signingInstructions}</div>
        </>}
      </div>
      <div className="mobile-row-doc-footer">
        <div className='doc-item-badge-wrapper'>
          {!!inkDoc.numOfInitials && <div className="badge badge-lg rounded-pill doc-item-badge mt-0">
            {`${inkDoc.numOfInitials} Initial${inkDoc.numOfInitials > 1 ? "s" : ""}`} <i className="mdi mdi-caps-lock ms-1" />
          </div>}
          {!!inkDoc.numOfSignatures && <div className="badge badge-lg rounded-pill doc-item-badge mt-0">
            {`${inkDoc.numOfSignatures} Signature${inkDoc.numOfSignatures > 1 ? "s" : ""}`} <img alt="signatures" src={signatureIcon} className="ms-1" />
          </div>}
          {!!inkDoc.numOfPages && <div className="badge badge-lg rounded-pill doc-item-badge mt-0">
            {`${inkDoc.numOfPages}`} <i className="mdi mdi-file-multiple-outline ms-1" />
          </div>}
        </div>
        {isDeliveryOptionUploaded && <a href={!inkDoc.isWithdrawn && canBeDownload() ? getBeUrl(`/order-doc/${inkDoc.id}/pdf/download`) : '#'}
          className={!inkDoc.isWithdrawn && canBeDownload() ? downloadBtnClassName : 'btn document-download pb-0 inactive-link'} >
          <i className="bx bx-download font-size-20" /> Download document
        </a>}
        {inkDoc.isWithdrawn && <div className="font-size-10">Document no longer available for download.</div>}
      </div>
    </div>)}

    {/* Return Label Document */}
    {!!order.returnShippingLabel &&
      <div className='mobile-row-doc-wrapper'>
        <div className="mobile-row-doc-header">
          <img src={downloadDoc} alt="download-document" />
          <span className="mobile-row-doc-title">Return Label Information</span>
        </div>
        <div className="mobile-row-doc-footer">
          <a href={getBeUrl(`order/${order.id}/return-label/download`)} className="btn document-download pb-0">
            <i className="bx bx-download font-size-20" /> Download document
          </a>
        </div>
      </div>
    }
  </>
}

DocumentExpectedSection.propTypes = {
  order: PropTypes.object,
  inkSignDocs: PropTypes.array,
  isDeliveryOptionUploaded: PropTypes.bool,
  downloadBtnClassName: PropTypes.string,
  canBeDownload: PropTypes.func,
}

export default DocumentExpectedSection;