import React, { useEffect, useState } from "react"
import { Container, Row, Col, Button, Alert, Pagination, PaginationItem, PaginationLink } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import AccessDenied from "pages/Error/AccessDenied";
import OrderCardGridView from "./Partial/OrderCard/GridView";
import OrderCardListView from "./Partial/OrderCard/ListView";
import SpinnerChase from "components/Shared/SpinnerChase";
import { useNavigate } from "react-router-dom";
import { perms, useAccess } from "context/access";
import { useDispatch, useSelector } from "react-redux";
import { route, routes } from "helpers/routeHelper";
import { doOrderListCleanup, getOrderList, setNotaryOrderFilterToggle, setViewModeToggle } from "store/actions";
import { map } from "lodash";
import classnames from "classnames";
import Order from "model/order";
import OrderNotary from "model/orderNotary";
import { useSocketOn, useSubscribeToOrderMessages } from "hooks/socket";
import socketEvent from "constants/socketEvent";

const OrderList = () => {

  // router hook that helps redirect
  const navigate = useNavigate();
  // hook that checks permissions
  const { iAmGranted, iAmNotGranted } = useAccess();
  // hook that dispatches redux actions
  const dispatch = useDispatch();

  /********** STATE **********/

  const { orders, totalCount, isLoadInProgress, ordersError, listParams, filterToggle, viewModeToggle } = useSelector(state => state.Order.List);

  const OrderCard = viewModeToggle === Order.UI_GRID_VIEW ? OrderCardGridView : OrderCardListView;

  const [pagination, setPagination] = useState({
    totalCount,
    page: listParams.page,
    pageSize: listParams.pageSize,
  });

  const { page, pageSize } = pagination;

  const pageCount = Math.ceil(totalCount / pageSize) || 1;

  // const currentPageOrders = orders.slice((page - 1) * pageSize, page * pageSize);

  /********** OTHER **********/

  const refreshOrders = () => dispatch(getOrderList(listParams));

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    refreshOrders()
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderListCleanup());
    }
  }, [filterToggle]);

  // runs whenever page number changes, including first render
  useEffect(() => {
    dispatch(getOrderList({ ...listParams, page }));
  }, [page]);


  // runs whenever total count changes
  useEffect(() => {
    setPagination(current => ({
      ...current,
      totalCount,
    }));
  }, [totalCount]);

  /********** HANDLERS **********/

  const handlePageClick = page => event => {
    // prevent redirecting
    event.preventDefault();

    setPagination(current => ({
      ...current,
      page,
    }));
  };

  const handleToggleFilter = filter => () => {
    dispatch(setNotaryOrderFilterToggle(filter));
    // reset local pagination
    setPagination({
      totalCount: 0,
      page: 1,
      pageSize: 9,
    });
  };

  /********** SOCKET *********/

  // start receiving messages updates
  useSubscribeToOrderMessages();

  const onMessageReceived = () => {
    dispatch(getOrderList(listParams, true));
  };

  // listen for changes on messages
  useSocketOn(socketEvent.messageReceived, onMessageReceived);

  return <React.Fragment>
    {iAmGranted(perms.view_orders) && <React.Fragment>
      <div className="page-content">
        <MetaTitle>My Orders</MetaTitle>
        <Container fluid>
          <div className="d-flex justify-content-between mb-2">
            <Breadcrumbs title="MY ORDERS" />
            <div className="d-flex">
              <Button
                color="primary"
                className={classnames("btn-rounded btn-sm filter-btn me-3", { "active bg-primary": filterToggle === OrderNotary.VIRTUAL_STATUS_COMPLETE })}
                // onClick={() => dispatch(setOrderFilterToggle(OrderNotary.VIRTUAL_STATUS_COMPLETE))}
                onClick={handleToggleFilter(OrderNotary.VIRTUAL_STATUS_COMPLETE)}
              >
                {OrderNotary.getVirtualStatusName(OrderNotary.VIRTUAL_STATUS_COMPLETE)}
              </Button>
              <Button
                color="primary"
                className={classnames("btn-rounded btn-sm filter-btn me-2", { "active bg-primary": filterToggle === OrderNotary.VIRTUAL_STATUS_PROCESSING })}
                // onClick={() => dispatch(setOrderFilterToggle(OrderNotary.VIRTUAL_STATUS_PROCESSING))}
                onClick={handleToggleFilter(OrderNotary.VIRTUAL_STATUS_PROCESSING)}
              >
                {OrderNotary.getVirtualStatusName(OrderNotary.VIRTUAL_STATUS_PROCESSING)}
              </Button>
              <div className="d-none vertical-divider bg-primary bg-soft mx-4" />
              <div className="d-none d-lg-flex">
                <div
                  className={classnames("icon-btn-rounded mx-2", { active: viewModeToggle === Order.UI_LIST_VIEW })}
                  onClick={() => dispatch(setViewModeToggle(Order.UI_LIST_VIEW))}
                >
                  <i className="mdi mdi-format-list-bulleted" />
                </div>
                <div
                  className={classnames("icon-btn-rounded mx-2", { active: viewModeToggle === Order.UI_GRID_VIEW })}
                  onClick={() => dispatch(setViewModeToggle(Order.UI_GRID_VIEW))}
                >
                  <i className="mdi mdi-view-grid-outline" />
                </div>
              </div>
            </div>
          </div>
          <Row>
            {ordersError && <Alert color="danger" className="fade show text-center">
              <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load orders
            </Alert>}
            {isLoadInProgress && <SpinnerChase className="mx-auto" />}
            {!isLoadInProgress && !ordersError && orders?.length === 0 && <Col>No orders found</Col>}
            {orders?.map(order => (
              <OrderCard key={order.id} order={order}>
                <Button color="primary" className="mt-auto w-100" onClick={() => navigate(route(routes.view_order, order.id))}>View Details</Button>
              </OrderCard>
            ))}
            {pageCount > 1 && <Pagination className="pagination pagination-rounded justify-content-center mb-2">
              <div className="pagination-parent">
                <PaginationItem disabled={page === 1}>
                  <PaginationLink
                    previous
                    href=""
                    onClick={handlePageClick(page - 1)}
                  />
                </PaginationItem>
                {map(Array(pageCount), (_item, i) => (
                  <PaginationItem active={i + 1 === page} key={i}>
                    <PaginationLink
                      onClick={handlePageClick(i + 1)}
                      href=""
                    >
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={page === pageCount}>
                  <PaginationLink
                    next
                    href=""
                    onClick={handlePageClick(page + 1)}
                  />
                </PaginationItem>
              </div>
            </Pagination>}
          </Row>
        </Container>
      </div>
    </React.Fragment>}
    {iAmNotGranted(perms.view_orders) && <AccessDenied />}
  </React.Fragment>
}

export default OrderList;
