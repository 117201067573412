import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import senPromoIcon from "../../assets/images/icon-sen-promo.png"
import { acceptNotaryPromotionToSen, declineNotaryPromotionToSen } from "helpers/backendHelper";
import { showError } from "helpers/utilHelper";
import Lottie from 'lottie-react';
import promotionToSenAnimation from 'assets/animations/promotion-to-sen.json';
import { useProfile } from "context/profile";
import { useAuth } from "context/auth";

const SenPromotion = () => {

  const { refreshProfile, notary } = useProfile();
  const { isImpersonation } = useAuth();

  const [showAnimation, setShowAnimation] = useState(false);

  const confirmPromotionToSen = () => {
    acceptNotaryPromotionToSen()
      .then(() => {
        setShowAnimation(true);
        refreshProfile();
      })
      .catch(() => {
        showError("Unable to confirm promotion to Super Express Notary!")
      });
  };

  const cancelPromotionToSen = () => {
    declineNotaryPromotionToSen()
      .then(() => {
        refreshProfile();
      })
      .catch(() => {
        showError("Unable to decline promotion to Super Express Notary!")
      });
  };

  return (
    <>
      {
        !!notary && !!notary.isPendingPromotion && !isImpersonation() && !showAnimation && !notary.forceTermsAgreement &&
        <SweetAlert
          title={<div className="text-primary">Congratulations!</div>}
          custom
          showCancel
          showConfirm
          cancelBtnText="Cancel"
          confirmBtnBsStyle="primary"
          confirmBtnCssClass="me-2 button-alert"
          cancelBtnBsStyle="secondary"
          cancelBtnCssClass="ms-2 button-alert"
          btnSize="default"
          confirmBtnText="Confirm"
          customIcon={senPromoIcon}
          onConfirm={() => confirmPromotionToSen()}
          reverseButtons={false}
          onCancel={() => cancelPromotionToSen()}
          style={{ backgroundColor: '#FFFFFF', width: 600, padding: "20px 40px" }}
          closeOnClickOutside={false}
        >
          <p className="text-primary mt-2">You&apos;ve been invited to become a Super Express Notary!</p>
          <p className="text-primary ">Congratulations! You are officially a Super Express Notary! Please select &apos;Confirm&apos; below to update your Mavsign notary status. Additionally, please sign the updated Terms and Conditions and GLBA form in your profile. Thank you for your continued support; we value and appreciate you!</p>

          <p className="text-primary fw-bold text-start mt-5">Please make sure to:</p>
          <div className="btn-light d-flex w-100 p-2 d-flex align-items-center">
            <div className="blue-circle me-3">1</div>
            <b>Agree to the Notary T&C</b>
          </div>
          <div className="btn-light d-flex w-100 mt-2 p-2 d-flex align-items-center mb-4">
            <div className="blue-circle me-3">2</div>
            <b>Complete the GLBA form</b>
          </div>
        </SweetAlert>
      }
      {
        showAnimation &&
        <div className="lottie-container">
          <Lottie
            animationData={promotionToSenAnimation}
            loop={false}
            style={{ width: "100%", height: "auto" }}
            className="lottie-obj"
            onComplete={() => setShowAnimation(false)}
          />
        </div>
      }
    </>
  )
}

export default SenPromotion
