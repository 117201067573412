import { combineReducers } from "redux";
import {
  GET_PROFILE,
  GET_PROFILE_OK,
  GET_PROFILE_ERR,
  AGREE_TO_PROFILE_TERMS,
  SAVE_PROFILE_BILLING,
  SAVE_PROFILE_SHIPPING,
  SAVE_PROFILE_CONTACT,
  SAVE_PROFILE_CREDENTIALS,
  SAVE_PROFILE_EXPERIENCE,
  SAVE_PROFILE_AREA,
  SAVE_PROFILE_OK,
  SAVE_PROFILE_ERR,
  DO_SAVE_PROFILE_CLEANUP,
  RESET_SAVE_PROFILE_ACTION_FLAG,
  SAVE_PROFILE_SHIPPING_AREA,
  CREATE_GLBA,
  CREATE_GLBA_OK,
  CREATE_GLBA_ERR,
  DO_CREATE_GLBA_CLEANUP,
  UPDATE_GLBA_GENERAL,
  UPDATE_GLBA_GENERAL_OK,
  UPDATE_GLBA_GENERAL_ERR,
  DO_UPDATE_GLBA_GENERAL_CLEANUP,
  UPDATE_GLBA_SECURITY,
  UPDATE_GLBA_SECURITY_OK,
  UPDATE_GLBA_SECURITY_ERR,
  DO_UPDATE_GLBA_SECURITY_CLEANUP,
  UPDATE_GLBA_ACCESS,
  UPDATE_GLBA_ACCESS_OK,
  UPDATE_GLBA_ACCESS_ERR,
  DO_UPDATE_GLBA_ACCESS_CLEANUP,

  SAVE_OUT_OF_OFFICE,
  SAVE_OUT_OF_OFFICE_OK,
  SAVE_OUT_OF_OFFICE_ERR,
  DO_SAVE_OUT_OF_OFFICE_CLEANUP
} from "./actionTypes";

/********** GET PROFILE **********/

const defaultSingleState = {
  // we use a default value of 'undefined' to distinguish between the case when:
  // 1. the notary has not been fetched yet (undefined)
  // 2. the notary has been fetched but it does not exist (null)
  notary: undefined,
  notaryError: null,
  isLoadInProgress: false,
}

const Single = (state = defaultSingleState, action) => {
  switch (action.type) {
    case GET_PROFILE:
      state = {
        ...state,
        notaryError: null,
        isLoadInProgress: true,
      };
      break
    case GET_PROFILE_OK:
      state = {
        ...state,
        notary: action.payload.response.notary,
        isLoadInProgress: false,
      };
      break
    case GET_PROFILE_ERR:
      state = {
        ...state,
        notary: null,
        notaryError: action.payload.error,
        isLoadInProgress: false,
      };
      break
  }
  return state;
}

/********** SAVE PROFILE **********/

const defaultFormState = {
  saved: null,
  saveError: null,
  isSaveInProgress: false,
}

const Form = (state = defaultFormState, action) => {
  switch (action.type) {
    case AGREE_TO_PROFILE_TERMS:
    case SAVE_PROFILE_BILLING:
    case SAVE_PROFILE_SHIPPING:
    case SAVE_PROFILE_CONTACT:
    case SAVE_PROFILE_CREDENTIALS:
    case SAVE_PROFILE_EXPERIENCE:
    case SAVE_PROFILE_AREA:
    case SAVE_PROFILE_SHIPPING_AREA:
      state = {
        ...state,
        saveError: null,
        isSaveInProgress: true,
      };
      break
    case SAVE_PROFILE_OK:
      state = {
        ...state,
        saved: true,
        isSaveInProgress: false,
      };
      break
    case SAVE_PROFILE_ERR:
      state = {
        ...state,
        saved: false,
        saveError: action.payload.error,
        isSaveInProgress: false,
      };
      break
    case DO_SAVE_PROFILE_CLEANUP:
      state = { ...defaultFormState };
      break
    case RESET_SAVE_PROFILE_ACTION_FLAG:
      if (!state.hasOwnProperty(action.payload.flag)) {
        break;
      }
      state = {
        ...state,
        [action.payload.flag]: null,
      };
      break
  }
  return state;
}

/********** GLBA FORM **********/
const defaultGlbaFormState = {
  notaryGlba: {},

  savedGeneral: null,
  savedGeneralError: null,
  isSaveGeneralInProgress: false,

  savedSecurity: null,
  savedSecurityError: null,
  isSaveSecurityInProgress: false,

  savedAccess: null,
  savedAccessError: null,
  isSaveAccessInProgress: false,

  savedCreate: null,
  savedCreateError: null,
  isSaveCreateInProgress: false,
};

const GlbaForm = (state = defaultGlbaFormState, action) => {
  switch (action.type) {
    case CREATE_GLBA:
      state = {
        ...state,
        notaryGlba: action.payload.data,
        savedCreate: null,
        savedCreateError: null,
        isSaveCreateInProgress: true,
      };
      break
    case CREATE_GLBA_OK:
      state = {
        ...state,
        notaryGlba: {
          ...state.notaryGlba,
          id: action.payload.response.id,
        },
        savedCreate: true,
        isSaveCreateInProgress: false,
      };
      break
    case CREATE_GLBA_ERR:
      state = {
        ...state,
        saved: false,
        savedCreateError: action.payload.error,
        isSaveCreateInProgress: false,
      };
      break
    case DO_CREATE_GLBA_CLEANUP:
      state = { ...defaultFormState };
      break

    // Step One
    case UPDATE_GLBA_GENERAL:
      state = {
        ...state,
        notaryGlba: action.payload.data,
        savedGeneral: null,
        savedGeneralError: null,
        isSaveGeneralInProgress: true,
      };
      break
    case UPDATE_GLBA_GENERAL_OK:
      state = {
        ...state,
        notaryGlba: {
          ...state.notaryGlba,
          id: action.payload.response.id,
        },
        savedGeneral: true,
        isSaveGeneralInProgress: false,
      };
      break
    case UPDATE_GLBA_GENERAL_ERR:
      state = {
        ...state,
        savedGeneral: false,
        saveGeneralError: action.payload.error,
        isSaveGeneralInProgress: false,
      };
      break
    case DO_UPDATE_GLBA_GENERAL_CLEANUP:
      state = { ...defaultFormState };
      break

    // Step Two
    case UPDATE_GLBA_SECURITY:
      state = {
        ...state,
        notaryGlba: action.payload.data,
        savedSecurity: null,
        savedSecurityError: null,
        isSaveSecurityInProgress: true,
      };
      break
    case UPDATE_GLBA_SECURITY_OK:
      state = {
        ...state,
        notaryGlba: {
          ...state.notaryGlba,
          id: action.payload.response.id,
        },
        savedSecurity: true,
        isSaveSecurityInProgress: false,
      };
      break
    case UPDATE_GLBA_SECURITY_ERR:
      state = {
        ...state,
        savedSecurity: false,
        savedSecurityError: action.payload.error,
        isSavedSecurityInProgress: false,
      };
      break
    case DO_UPDATE_GLBA_SECURITY_CLEANUP:
      state = { ...defaultFormState };
      break

    // Step Three
    case UPDATE_GLBA_ACCESS:
      state = {
        ...state,
        notaryGlba: action.payload.data,
        savedAccess: null,
        savedAccessError: null,
        isSaveAccessInProgress: true,
      };
      break
    case UPDATE_GLBA_ACCESS_OK:
      state = {
        ...state,
        notaryGlba: {
          ...state.notaryGlba,
          id: action.payload.response.id,
        },
        savedAccess: true,
        isSaveAccessInProgress: false,
      };
      break
    case UPDATE_GLBA_ACCESS_ERR:
      state = {
        ...state,
        savedAccess: false,
        savedAccessError: action.payload.error,
        isSaveAccessInProgress: false,
      };
      break
    case DO_UPDATE_GLBA_ACCESS_CLEANUP:
      state = { ...defaultFormState };
      break
  }
  return state;
}

/********** SAVE OUT OF OFFICE **********/
const defaultOutOfOfficeState = {
  saved: null,
  saveError: null,
  isSaveInProgress: false,
}
const OutOfOfficeForm = (state = defaultOutOfOfficeState, action) => {
  switch (action.type) {
    case SAVE_OUT_OF_OFFICE:
      state = {
        ...state,
        saveError: null,
        isSaveInProgress: true,
      };
      break
    case SAVE_OUT_OF_OFFICE_OK:
      state = {
        ...state,
        saved: true,
        isSaveInProgress: false,
      };
      break
    case SAVE_OUT_OF_OFFICE_ERR:
      state = {
        ...state,
        saved: false,
        saveError: action.payload.error,
        isSaveInProgress: false,
      };
      break
    case DO_SAVE_OUT_OF_OFFICE_CLEANUP:
      state = { ...defaultFormState };
      break
  }
  return state;
}

export default combineReducers({
  Single,
  Form,
  GlbaForm,
  OutOfOfficeForm,
})
